import React, { useEffect, useState } from "react";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import {  RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import DiagnosticLabReport from "./diagnosticLabReport";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey, setBundlePushError, setBundlePushResponse, setSelectedBundle } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { createDiagnosticBundle, pushBundle, retryDataPush } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import DocumentReference from "./DocumentReference";
import HealthRecordHelper from "../utils/utils";
import LoadingPopup from "../LoadingPopup";
import Encounter from "./Encounter";

const DiagnosticRecord = ({ handleCancle, payloadHelper, title, setTitleError, setTitleSubmitted}) => {
  const { patientData, selectedBundle, bundlepushError } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [encounterItem, setEncounterItem] = useState([])
  const [reportsList, setReportsList] = useState([]);
  const [docRefList, setDocRefList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reportsError, setReportsError] = useState("");
  const [docRefError, setDocRefError] = useState("");
  const [sectionError, setSectionError] = useState("");
  const [encounterError, setEncounterError] = useState("");
  const [isEncounterPopupOpen, setIsEncounterPopupOpen] = useState(false)
  const [openDocRefPopup, setOpenDocRefPopup] = useState(false);
  const [loadingpopup, setLoadingPopup] = useState(false);
  const [isLinkingFailed, setIsLinkingFailed] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.compositionStatus || values.compositionStatus.value === "Select Status") {
      errors.compositionStatus = "Please select composition status.";
    }
    if(!title) {
      setTitleError("Please provide title")
    }
    if(reportsList.length === 0 && docRefList.length === 0) {
      setSectionError("At least one lab report or document reference is required.");
    }
    if(encounterItem.length === 0) {
      setEncounterError("Encounter is required");
    }
    return errors;
  };

  useEffect(() => {
    if(reportsList.length !== 0 || docRefList !== 0) setSectionError("");
    if(reportsList.length !== 0) setReportsError("");
    if(docRefList.length !== 0) setDocRefError("");
    if(encounterItem.length !== 0) setEncounterError("");
  },[reportsList, docRefList, encounterItem])

  useEffect(() => {
    if(selectedBundle?.id){
      const payload = {
        ...payloadHelper,
        patientId: patientData?.uuid,
        bundleData: selectedBundle,
      }
      if(isLinkingFailed) {
        payload.previous = true;
        dispatch(retryDataPush(payload))
      } else {
        dispatch(pushBundle(payload));
      }
      setLoadingPopup(true);
    }
  },[dispatch, selectedBundle?.id])

  useEffect(() => {
    if(bundlepushError?.statusCode){
      setLoadingPopup(false);
      setIsLinkingFailed(true)
      dispatch(setBundlePushError({}))
      dispatch(setSelectedBundle(null))
    }
  }, [bundlepushError, dispatch])

  const structurePayloadWithAllData = (values) => {
      const payload = {
        status: values.compositionStatus.value,
        subject: HealthRecordHelper.getStructurePatientData(patientData),
        encounter: encounterItem[0]?.data,
        entry: [
          reportsList.length > 0
            ? { diagnosticLabReportEntry: reportsList.map((item) => item.labReport) }
            : null,
          docRefList.length > 0
            ? { documentReferenceEntry: docRefList.map((item) => item.data) }
            : null,
        ].filter(Boolean),              
      };
      return payload;
  };

  useEffect(() => {
      return () => {
          dispatch(setSelectedBundle(null))
          dispatch(setBundlePushResponse({}))
          dispatch(setBundlePushError({}))
      }
  }, [dispatch])


  return (
      <>
          <Formik
              initialValues={{
                  compositionStatus:{ label: "Select Status", value: "Select Status" },
                  recordType: { label: "Select Record Type", value: "Select Record Type" },
              }}
              validate={validate}
              onSubmit={(values) => {
                dispatch(setSelectedBundle(null));
                if((reportsList.length === 0 && docRefList.length === 0) || encounterItem.length === 0) {
                    return;
                }
                const payload = structurePayloadWithAllData(values);
                dispatch(createDiagnosticBundle(payload))
              }}
          >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <>
                    <form 
                      onSubmit={(e) => {
                        setTitleSubmitted(true)
                        setSubmitAttempted(true)
                        handleSubmit(e);
                      }} 
                      className="h-full w-full flex flex-col gap-1 justify-between"
                    >                      
                      <div className="space-y-4 h-[42vh] w-full flex flex-col justify-between overflow-y-auto pr-2 met-scrollbar">
                        <div className="flex h-full flex-col gap-2">
                            <SelectDropdown
                                label="Composition Status"
                                name="compositionStatus"
                                customClasses="w-1/2"
                                isRequired={true}
                                value={values.compositionStatus}
                                options={[{ label: "Select Status", value: "Select Status" }, ...RECORD_STATUS]}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue("compositionStatus", selectedOption)
                                }
                            />
                            <div className="flex flex-col">
                                <ItemManager
                                    key={`encounterEntry`}
                                    title={
                                        <>
                                        Encounter entry <span className="text-red-500">*</span>
                                        </>
                                    }
                                    items={encounterItem}
                                    setItems={setEncounterItem}
                                    setIsPopupOpen={(value) => {
                                        if(encounterItem.length < 1){
                                            setIsEncounterPopupOpen(value)
                                        }else setEncounterError("Only one encounter is allowed")
                                    }}
                                    customHeight="52"
                                />
                                {submitAttempted && encounterError && (
                                    <Label fontSize="md" color="red-500">
                                        {encounterError}
                                    </Label>
                                )}
                            </div>
                            <div className="flex flex-col gap-2 border-2 border-gray-200 rounded-md px-4 py-2">
                            <Heading type={HEADING.H2}> Section <span className="text-red-500">* {submitAttempted && sectionError && (
                                    <Label fontSize="sm " color="red-500">
                                            ({sectionError})
                                    </Label>
                                )}</span></Heading>
                              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                <div className="flex flex-col gap-2">
                                  <ItemManager
                                      title="Lab report"
                                      items={reportsList}
                                      setItems={setReportsList}
                                      setIsPopupOpen={(value) => {
                                        if(reportsList.length < 1) setIsPopupOpen(value);
                                        else setReportsError("Only one report is allowed");
                                        
                                      }}
                                      customHeight="52"
                                  />
                                  {reportsError && <Label fontSize="sm" fontWeight="bold" color="red-500">{reportsError}</Label>}
                                </div>
                                <div className="flex flex-col gap-2">
                                  <ItemManager
                                      title="Document reference"
                                      items={docRefList}
                                      setItems={setDocRefList}
                                      setIsPopupOpen={(value) => {
                                        if(docRefList.length < 1){
                                            setOpenDocRefPopup(value)
                                        }else setDocRefError("Only one document reference is allowed")
                                        
                                      }}
                                      customHeight="52"
                                  />
                                  {docRefError && <Label fontSize="sm" fontWeight="bold" color="red-400">{docRefError}</Label>}
                                </div>
                              </div>
                            </div>
                            {/* {sectionError && <Label fontSize="md" color="red-500">{sectionError}</Label>} */}
                        </div>
                      </div>
                      <div className="flex justify-end gap-4">
                        <Button 
                            onClickCb={handleCancle} 
                            variant={BUTTON_VARIANTS.OUTLINED}
                        >Cancel</Button>
                        <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                            {isLinkingFailed ? "Try Again" : "Link to ABDM"}
                        </Button>
                      </div>
                  </form>
                </>
              )}
          </Formik>
          {isEncounterPopupOpen && 
            <Encounter
                open={isEncounterPopupOpen}
                onConfirm={setEncounterItem}
                close={() => setIsEncounterPopupOpen(false)}
            />
        }
          {isPopupOpen && 
            <DiagnosticLabReport 
              onConfirm={setReportsList} 
              close={() => setIsPopupOpen(false)}
            />
          }
          {openDocRefPopup &&
            <DocumentReference
              open={openDocRefPopup}
              onConfirm={setDocRefList}
              close={() => setOpenDocRefPopup(false)}
            />
          }
          <LoadingPopup
            isOpen={loadingpopup} 
            handleCancle = {() => {
              setLoadingPopup(false)
              handleCancle()
            }}
            handleClose = {() => setLoadingPopup(false)}
          />
      </>
  );
};

export default DiagnosticRecord;
