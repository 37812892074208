export const PAGE_STATE = {
    PRE_LOADING: 'preloading',
    LOADING: 'loading',
    PAGE_READY: 'ready',
}

export const MAIN_PATHS = {
    DASHBOARD: '/dashboard/*',
    SCHEDULING: "/scheduling/*",
    PATIENT_ENCOUNTER: '/encounter/:patientId/:encounterId/*',
    PATIENTS: '/patients/*',
    COMMUNICATIONS: "/communications/*",
    UNSIGNED_VISIT: "/unsigned-visit/*",
    BILLING: '/billing/*',
    REPORTS: '/reports/*',
    SETTINGS: "/settings",
    ROOT: "/",
    REDIRECT_TO_DASHBOARD: "/dashboard",
    REDIRECT_TO_PATIENTS: "/patients",
}

export const COMMUNICATIONS_TAB_PATHS = {
    TASKS: "/tasks/*",
    CONTACT_DIRECTORY: "/contact-directory/*"
}

export const PATIENTS_ROUTES = {
    ADD: "/add",
    DASHBOARD: "/dashboard/:patientId",
    ROOT: "/:patientId/*",
}

export const BILLING_ROUTES = {
    BILLING_READY: "/billing-ready",
    SUPERBILL: "/superbill",
    PAYMENTS: "/payments"
}

export const ENCOUNTER_ROUTES = {
    CLINICAL_DATA: "/clinical-data",
    ENCOUNTER: "/patient-encounter",
    HISTORY: "/history",
    TEMPLATES: "/templates"
}

export const SCHEDULING_ROUTES = {
    CALENDAR: "/Calendar",
    LIST: '/list',
}

export const SETTINGS_PATHS = {
    APPOINTMENT_ROOT: '/settings/appointment/*',
    MY_ACCOUNT_ROOT: '/settings/my-account/*',
    PROVIDER_GROUP_ROOT: '/settings/provider-group/*',
    TEMPLATES_ROOT: '/settings/templates/*',
    MASTER_ROOT: '/settings/master/*',
    APPOINTMENT_AVAILABILITY: '/settings/appointment/availability',
    APPOINTMENT_COLOR_CONFIG: '/settings/appointment/color-config',
    APPOINTMENT_CANCELLATION: '/settings/appointment/cancellation',
    MY_ACOCUNT_MY_PROFILE: '/settings/my-account/my-profile',
    MY_ACOCUNT_PROVIDER_GROUP: '/settings/my-account/provider-group',
    MY_ACOCUNT_USERS: '/settings/my-account/users',
    MY_ACOCUNT_NOTIFICATION: '/settings/my-account/notification',
    MY_ACOCUNT_PRINT_CONFIGURATION: '/settings/my-account/print-configuration',
    PROVIDER_GROUP_PROFILE: '/settings/provider-group/profile',
    PROVIDER_GROUP_LOCATIONS: '/settings/provider-group/locations',
    PROVIDER_GROUP_LOCATIONS_DETAILS: '/settings/provider-group/locations/details',
    PROVIDER_GROUP_LOCATIONS_PATIENTS: '/settings/provider-group/locations/patients',
    PROVIDER_GROUP_LOCATIONS_USERS: '/settings/provider-group/locations/users',
    PROVIDER_GROUP_DEPARTMENTS: '/settings/provider-group/departments',
    PROVIDER_GROUP_USERS: '/settings/provider-group/users',
    PROVIDER_GROUP_ROLES_RESPONSIBILITIES: '/settings/provider-group/roles-responsibilties',
    TEMPLATES_VISIT_NOTES: '/settings/templates/visitNotes',
    TEMPLATES_NEW_VISIT_NOTES: '/settings/templates/new-visit-notes',
    MASTER_VITALS_TESTS: '/settings/master/vitalsTests',
}

export const MET_COLORS = {
    PRIMARY: "#1B5984",
    SECONDARY: "#ccecff80",
    DARK_GRAY: "#4c4c4c99",
    GRAY: "#E7E7E7"
}

export const SCREEN_SIZES = {
    XS: "xs",
    SM: "sm",
    MD: "md",
    LG: "lg",
}

export const VALIDATION_REGEX = {
    NAME_REGEX: /^[a-zA-Z]*$/,
    AADHAR_REGEX: /^\d{4}-\d{4}-\d{4}$/,
    MOBILE_NUM_REGEX: /^[6789]\d{9}$/,
    ZIP_CODE_REGEX: /^\d{6}$/,
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
    AGE_REGEX: /^(0?[1-9]|[1-9][0-9]|[1][0-2][0-9]|130)$/,
    NUMBER_REGEX: /^[0-9]+$/,
    NUMBER_WITH_DECIMAL_REGEX: /^[0-9]+(?:\.[0-9]+)?$/,
    WEBSITE_REGEX: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+([a-zA-Z]){2,}(\/[a-zA-Z0-9#]+\/?)*$/,
    GST_NUMBER_REGEX: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
    CITY_REGEX:/^[a-zA-Z\s]*$/
}

export const FIELDS_CONTSTANT_NAMES = {
    "BMI": "Body Mass Index",
    "providerId": 'Provider',
    "blockDaysStartTime": "Start Time",
    "blockDaysEndTime": "End Time",
    'availabilityLocationId': 'Location',
    'serviceLocationId': 'Service Location',
    'pos': 'Place of Service',
    'dos': 'Date',
    'renderingProviderId': 'Rendering Provider',
    'patientId': "Patient Name",
    'startDate': "start date",
    'locationIds': 'Work location'

}


export const NO_LOADER_FOR_API_ENDPOINT = ["notification-log/details","abha/m2/care-context-link", "abha/m2/push/data/retry"]

