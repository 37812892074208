import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { FORM_FIELDS_FOR_IMMUNIZATION, IMMUNIZATION_STATUS_OPTIONS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import Icons from "../../../../../components/icons/Icons";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import Organization from "./Organization";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import Location from "./Location";
import Label from "../../../../../components/common-components/label/Label";
const fields = [
    { fieldName: FORM_FIELDS_FOR_IMMUNIZATION.STATUS, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_IMMUNIZATION.VACCINE, isRequired: true },
    { fieldName: FORM_FIELDS_FOR_IMMUNIZATION.OCCURRENCE, isRequired: true },
    { fieldName: FORM_FIELDS_FOR_IMMUNIZATION.DOSE_NUMBER, isRequired: true },
]
const validationSchema = getValidationSchema(fields);

const Immunization = ({onConfirm, close}) => {
    const [locationList, setLocationList] = useState([]);

    const [isLocationPopupOpen, setIsLocationPopupOpen] = useState(false);
    const [locationError, setLocationError] = useState("");
    useEffect(() => {
        if(locationList.length > 0) setLocationError("");
    }, [locationList])

    return(
        <>
            <Formik
                initialValues={{
                    [FORM_FIELDS_FOR_IMMUNIZATION.STATUS]:"",
                    [FORM_FIELDS_FOR_IMMUNIZATION.VACCINE]:"",
                    [FORM_FIELDS_FOR_IMMUNIZATION.OCCURRENCE]:"",
                    [FORM_FIELDS_FOR_IMMUNIZATION.LOT_NUMBER]:"",
                    [FORM_FIELDS_FOR_IMMUNIZATION.EXPIRATION_DATE]:"",
                    [FORM_FIELDS_FOR_IMMUNIZATION.SITE]:"",
                    [FORM_FIELDS_FOR_IMMUNIZATION.ROUTE]:"",
                    [FORM_FIELDS_FOR_IMMUNIZATION.REASON_CODE]:"",
                    [FORM_FIELDS_FOR_IMMUNIZATION.DOSE_NUMBER]:"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const data = Object.fromEntries(
                        Object.entries({
                            status: values[FORM_FIELDS_FOR_IMMUNIZATION.STATUS]?.value,
                            vaccineCode: values[FORM_FIELDS_FOR_IMMUNIZATION.VACCINE] && { text: values[FORM_FIELDS_FOR_IMMUNIZATION.VACCINE] },
                            occurrenceDateTime: values[FORM_FIELDS_FOR_IMMUNIZATION.OCCURRENCE],
                            location: locationList.length ? locationList.map((location) => location.data)[0] : undefined,
                            lotNumber: values[FORM_FIELDS_FOR_IMMUNIZATION.LOT_NUMBER],
                            expirationDate: values[FORM_FIELDS_FOR_IMMUNIZATION.EXPIRATION_DATE],
                            site: values[FORM_FIELDS_FOR_IMMUNIZATION.SITE] && { text: values[FORM_FIELDS_FOR_IMMUNIZATION.SITE] },
                            route: values[FORM_FIELDS_FOR_IMMUNIZATION.ROUTE] && { text: values[FORM_FIELDS_FOR_IMMUNIZATION.ROUTE] },
                            reasonCode: values[FORM_FIELDS_FOR_IMMUNIZATION.REASON_CODE] && [{ text: values[FORM_FIELDS_FOR_IMMUNIZATION.REASON_CODE] }],
                            protocolApplied: [{ doseNumberString: values[FORM_FIELDS_FOR_IMMUNIZATION.DOSE_NUMBER] }]
                        }).filter(([_, v]) => v !== undefined && v !== "")
                    );
                
                    if (Object.keys(data).length === 0) {
                        alert("At least one field is required.");
                        return;
                    }
                
                    onConfirm((prev) => [...prev, { type: `Immunization ${prev.length + 1}`, data }]);
                    close();
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                    <div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl min-h-[35%] max-h-[90%] overflow-y-auto">
                        <div className="flex justify-between items-center border-b pb-3">
                            <Heading type={HEADING.H2} className="text-lg font-semibold">Immunization</Heading>
                            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                                <Icons iconName={"closeIcon"}/>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className=" grid grid-cols-2 gap-2">
                                <SelectDropdown
                                    label={"Status"}
                                    name={FORM_FIELDS_FOR_IMMUNIZATION.STATUS}
                                    isRequired={true}
                                    customClasses="w-full"
                                    placeholder="Select"
                                    value={values[FORM_FIELDS_FOR_IMMUNIZATION.STATUS]}
                                    options={IMMUNIZATION_STATUS_OPTIONS}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue(FORM_FIELDS_FOR_IMMUNIZATION.STATUS, selectedOption)
                                    }
                                />
                                <Input
                                    label="Vaccine Code/Text"
                                    placeholder="Vaccine product administered"
                                    name={FORM_FIELDS_FOR_IMMUNIZATION.VACCINE}
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_IMMUNIZATION.VACCINE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_IMMUNIZATION.VACCINE, e.target.value)}
                                />
                                <Input
                                    label="Occurrence"
                                    placeholder="Vaccine administration date"
                                    type="datetime-local"
                                    name={FORM_FIELDS_FOR_IMMUNIZATION.OCCURRENCE}
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_IMMUNIZATION.OCCURRENCE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_IMMUNIZATION.OCCURRENCE, e.target.value)}
                                />
                                <div className="col-span-2">
                                    <ItemManager
                                        key={`ImmunizationLocation`}
                                        title={ "Location"}
                                        items={locationList}
                                        setItems={setLocationList}
                                        setIsPopupOpen={(value) => {
                                            if(locationList.length < 1) setIsLocationPopupOpen(value)
                                            else setLocationError("Only one location is allowed")
                                        }}
                                        customHeight="52"
                                    />
                                    {locationError && (
                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                            {locationError}
                                        </Label>
                                    )}
                                </div>
                                <Input
                                    label="LOT number"
                                    placeholder="Vaccine lot number"
                                    name={FORM_FIELDS_FOR_IMMUNIZATION.LOT_NUMBER}
                                    value={values[FORM_FIELDS_FOR_IMMUNIZATION.LOT_NUMBER]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_IMMUNIZATION.LOT_NUMBER, e.target.value)}
                                />
                                <Input
                                    label="Expiration Date"
                                    name={FORM_FIELDS_FOR_IMMUNIZATION.EXPIRATION_DATE}
                                    placeholder="Vaccine expiration date"
                                    type="date"
                                    value={values[FORM_FIELDS_FOR_IMMUNIZATION.EXPIRATION_DATE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_IMMUNIZATION.EXPIRATION_DATE, e.target.value)}
                                />
                                <Input
                                    label="Site"
                                    name={FORM_FIELDS_FOR_IMMUNIZATION.SITE}
                                    placeholder="Body site vaccine was administered"
                                    value={values[FORM_FIELDS_FOR_IMMUNIZATION.SITE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_IMMUNIZATION.SITE, e.target.value)}
                                />
                                <Input
                                    label="Route"
                                    name={FORM_FIELDS_FOR_IMMUNIZATION.ROUTE}
                                    placeholder="How vaccine entered body"
                                    value={values[FORM_FIELDS_FOR_IMMUNIZATION.ROUTE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_IMMUNIZATION.ROUTE, e.target.value)}
                                />
                                <Input
                                    label="Reason Code/Text"
                                    name={FORM_FIELDS_FOR_IMMUNIZATION.REASON_CODE}
                                    placeholder="Why immunization occurred"
                                    value={values[FORM_FIELDS_FOR_IMMUNIZATION.REASON_CODE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_IMMUNIZATION.REASON_CODE, e.target.value)}
                                />
                                <Input
                                    label="Dose Number"
                                    name={FORM_FIELDS_FOR_IMMUNIZATION.DOSE_NUMBER}
                                    placeholder="Dose number within series"
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_IMMUNIZATION.DOSE_NUMBER]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_IMMUNIZATION.DOSE_NUMBER, e.target.value)}
                                />
                            </div>
                            <div className="flex justify-end">
                                <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
        {isLocationPopupOpen &&
            <Location
                onConfirm={setLocationList}
                close={() => setIsLocationPopupOpen(false)}
            />
        }
    </>
    )
}
export default Immunization;