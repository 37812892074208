class HealthRecordHelper {
    static getStructurePatientData(patientData) {
        return {
            name: [
                {
                text: `${patientData.firstName} ${
                    patientData.middleName ? patientData.middleName + " " : ""
                }${patientData.lastName}`,
                },
            ],
            ...(patientData?.mobileNumber && { telecom: [
                {
                system: "phone",
                value: String(patientData.mobileNumber),
                },
            ]}),
            ...(patientData.gender && {gender: patientData.gender}),
            birthDate: patientData.dob.split("T")[0],
            ...(patientData?.Address?.length > 0 && {address: patientData.Address.map((addr) => ({
                use: 'home',
                text: `${addr.addressLine1} ${addr.addressLine2} ${addr.country}`,
            }))}),
        };
    }

    static removeEmptyFields(obj) {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => 
                value !== undefined && 
                value !== null && 
                value !== "" && 
                !(typeof value === "object" && Object.keys(value).length === 0)
            )
        );
    }
}

export default HealthRecordHelper;
