import React, { useEffect, useState } from "react";
import Button from "../../../../../components/common-components/button/Button";
import EyeIcon from "../../../../../components/icons/vectors/EyeIcon";
import { HEADING } from "../../../../../components/common-components/heading/Constants";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { componentKey as PatientDashboardComponentKey, setBundlesData } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import FHIRBundleViewer from "./FHIRBundleViewer/FHIRBundleViewer";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";

const formatToIST = (utcDate) => {
    if (!utcDate) return null;
    const date = new Date(utcDate);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    return date.toLocaleString("en-IN", { dateStyle: "medium", timeStyle: "short", timeZone: "Asia/Kolkata" });
};

function ViewRecords({handleCancle}) {
    const { bundlesData } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [isViewRecordOpen, setIsViewRecordOpen] = useState(false);
    const [selectedViewRecord, setSelectedViewRecord] = useState({})
    const [selectedRecordTitle, setSelectedRecordTitle] = useState("");
    
    const records = bundlesData?.flatMap(bundle => {
        if (!bundle?.entry) return [];
    
        return bundle.entry
            .map(entry => {
                if (entry.resource?.resourceType === "Composition") {
                    return {
                        id: bundle.id,
                        resourceType: entry?.resource?.title || "Health Information",
                        lastUpdated: formatToIST(bundle.meta?.lastUpdated)
                    };
                }
                return null;
            })
            .filter(Boolean);
    });

    return (
        <>
            <div className="w-full flex flex-col gap-6 p-5 max-h-[60vh] overflow-y-auto rounded-xl">
                <Heading type={HEADING.H1} className="text-2xl font-bold text-gray-900 border-b pb-2">Patient Records</Heading>
                <div className="space-y-4">
                    {records?.length > 0 ? records.map((record) => (
                        <div
                            key={record.id}
                            className="flex justify-between items-center p-4 bg-white shadow-md rounded-lg border border-gray-200 hover:shadow-lg transition-all duration-200"
                        >
                            <div className="flex items-center gap-4">
                                <span className="px-4 py-1 text-sm font-semibold bg-blue-200 text-met-primary rounded-lg">
                                    {record.resourceType}
                                </span>
                                <div className="text-gray-600 text-sm flex items-center gap-1">
                                    <span>{record.lastUpdated}</span>
                                </div>
                            </div>
                            <Button 
                                variant="outline" 
                                className="flex items-center gap-2 px-4 py-2 border-met-primary text-met-primary hover:bg-blue-100 rounded-lg transition-all"
                                onClickCb={() => {
                                    setSelectedViewRecord(bundlesData.find((bundle) => bundle.id === record.id))
                                    setIsViewRecordOpen(true);
                                    setSelectedRecordTitle(record.resourceType)
                                }}
                            >
                                <EyeIcon/> View
                            </Button>
                        </div>
                    )) : (
                        <div className="text-gray-500 text-center py-10 text-lg font-medium">No records available</div>
                    )}
                </div>
            </div>
            <div className="flex gap-4 justify-end">
                <Button 
                    onClickCb={handleCancle} 
                    variant={BUTTON_VARIANTS.OUTLINED}
                >Close</Button>
            </div>
            {isViewRecordOpen && 
                <FHIRBundleViewer 
                    title ={selectedRecordTitle}
                    bundle={selectedViewRecord} 
                    close={() => {
                        setIsViewRecordOpen(false)
                        setSelectedViewRecord({})
                    }}
                />
            }
        </>
    );
}

export default ViewRecords;