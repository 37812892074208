import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../components/icons/Icons';
import Input from '../../../components/common-components/input/Input';
import { FORM_FIELDS_NAMES, ABHA_FIELDS_NAMES, ABHA_OPTIONS, ABHA_VERIFICATION_OPTIONS, ABHA_ERROR_MESSAGES } from './Constants';
import {  getExistingABHADetails } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import ValidateOTPModal from './ValidateOTPModal';
import RadioButton from '../../../components/common-components/radioButton/RadioButton';
import { componentKey, setCreatePatientFromAadhaar, setOpenValidateGetOtpModal, setOpenGetOrCreatePatientModal, setIsCreateABHA } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import Label from '../../../components/common-components/label/Label';
import { useLocation, useNavigate } from 'react-router-dom';
import General from '../../../libs/utility/General';
import ImportFromAadhaar from './ImportFromAadhaar';
import QRcode from "../../../assets/images/QRCode.png"
import { io } from 'socket.io-client';
import { addNotifications } from '../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../components/common-components/toaster/MetToaster';

const socketURL = process.env.REACT_APP_API_URL.replace(/\/api$/, '');

const ImportFromABHA = ({ open, close }) => {
	const { openValidateGetOtpModal, createPatientFromAadhaar } = useSelector((state) => state[componentKey])
    const [selectedABHAOption, setSelectedABHAOption] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [selectedOtpOption, setSelectedOtpOption] = useState("");
    const [showErrorText, setShowErrorText] = useState(null);
    const [openImportUsingAadhaarModal, setOpenImportUsingAadhaarModal] = useState(false);
	const dispatch = useDispatch();
    // const location = useLocation();
    // const navigate = useNavigate()
    const socketRef = useRef(null);
  
    useEffect(() => {
        if (selectedABHAOption === "Scan and Share") {
            if (!socketRef.current) {
                socketRef.current = io(socketURL);
            }

            const socket = socketRef.current;

            socket.on("connect", () => {
                startEmitting();
            });

            socket.on("getCreatedPatient", (data) => {
                if(data){
                    dispatch(addNotifications({ message: "Details Fetched successfully!", variant: TOASTER_VARIANT.SUCCESS }))
                    dispatch(setOpenGetOrCreatePatientModal("detailsByScanAndShare"))
                    dispatch(setCreatePatientFromAadhaar(data))
                    stopEmitting();
                }
                
            });

            return () => {
                if (socket) {
                    socket.disconnect();
                    socketRef.current = null;
                }
            };
        }
    }, [selectedABHAOption]);

    const startEmitting = () => {
        if (socketRef.current) {
            socketRef.current.emit("getCreatedPatient");
        }
    };

    const stopEmitting = () => {
        if (socketRef.current) {
            socketRef.current.emit("getCreatedPatient", { action: "stop" });
        }
    };


    const handleSelectedABHAChange = (option) => {
        setSelectedABHAOption(option);
        setSelectedOtpOption("");
        setSearchValue("")
        setShowErrorText({show: false, message:""})
    };

    const handleSelectedOtpChange = (option) => {
        setSelectedOtpOption(option);
    }


    const handleSearchValue = (value) => {
        if (selectedABHAOption === "Aadhar Number") {
            setSearchValue(General.formateAadhar(value));
        } else if (selectedABHAOption === "ABHA Number") {
            if (value.length > 17) return;
            setSearchValue(General.formatABHANumber(value));
        } else if (selectedABHAOption === "ABHA Address") {
            if(value.length > 22) return;
            setSearchValue(value);
        } else if (selectedABHAOption === "Mobile Number") {
            if (!/^\d*$/.test(value) || value.length > 10) {
                return;
            }
            setSearchValue(value);
        }
    };
    const validateSearchValue = (value) => {
        if (selectedABHAOption === "Aadhar Number") {
            return value?.length === 14 ? true : false;
            
        } else if (selectedABHAOption === "ABHA Number") {
            return value?.length === 17 ? true : false;
            
        } else if (selectedABHAOption === "ABHA Address") {
            if(value?.endsWith('@sbx')) {
                const abhaPrefix = value.slice(0, -4);
                return abhaPrefix.length >= 8 && abhaPrefix.length <= 18;
            } else{
                return false
            }
        } else if (selectedABHAOption === "Mobile Number") {
            return (!/^[6-9]\d{9}$/.test(value) || value.length !== 10) ? false : true;
        }
    }

    const field = selectedABHAOption.toLowerCase().split(' ').map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)).join('');

	return (
		<>
			<Formik
				initialValues={{
					[ABHA_FIELDS_NAMES.AADHAR_NUMBER]: '',
				}}
				enableReinitialize
				onSubmit={async () => {
                    dispatch(setOpenGetOrCreatePatientModal("detailsByImport"))
                    if(searchValue === "" || ((field === "abhaAddress" || field === "abhaNumber") && selectedOtpOption === "")){
                        setShowErrorText({show : true, message: "Please fill in all mandatory fields."});
                        return;
                    }
                    if (!validateSearchValue(searchValue)) {
                        let errorMessage;
                        if (field === 'abhaAddress') {
                            errorMessage = !searchValue?.endsWith('@sbx') 
                                ? ABHA_ERROR_MESSAGES[field + "1"] 
                                : ABHA_ERROR_MESSAGES[field + "2"];
                        } else {
                            errorMessage = ABHA_ERROR_MESSAGES[field];
                        }
                        setShowErrorText({ show: true, message: errorMessage });
                        return;
                    }
                    const payload = {
                        key: field,
                        [field]: selectedABHAOption === "Aadhar Number" ? searchValue.replace(/-/g, '') : searchValue,
                        ...(selectedOtpOption && { sentTo: selectedOtpOption }),
                    };

					dispatch(getExistingABHADetails({getExistingABHADetailsData:payload}));
				}}
			>	
				{({
					submitForm,
					isSubmitting,
					handleReset,
					values,
					handleSubmit,
					setFieldValue,
					handleBlur,
					...props
				}) => {
					return (
                        <>
						<Form onSubmit={handleSubmit}>
							<div>
								<ModalComponent
									open={open}
									title={'Import Data From ABHA'}
									footerButton={
										<div className={`${showErrorText?.show ? "w-full flex items-center justify-between": ""}`}>
                                            {showErrorText?.show && <Label asteriskPosition="start" color="red-500" fontSize="sm">* {showErrorText?.message}</Label>}
                                            {selectedABHAOption !== "Scan and Share" &&
                                                <Button
                                                    type="submit"
                                                    variant={BUTTON_VARIANTS.CONTAINED}
                                                    customBtnClass="text-met-primary"
                                                >
                                                    Generate OTP
                                                </Button>
                                            }
                                        </div>
									}
									icon={<Icons iconName="closeIcon" />}
									close={close}
									customClasses="w-[900px]"
								>
									<div className="p-2">
                                        <div className="flex flex-col gap-4 items-start pt-6">
                                            <div className="flex flex-col gap-5">
                                                <div className="flex flex-wrap gap-4 mt-2">
                                                    {ABHA_OPTIONS.GET_OPTIONS.map((option) => (
                                                        <div key={option.value} className={`border ${selectedABHAOption === option.value ? "border-blue-500 border-2":"border-gray-400"} py-2 px-2 rounded-lg flex items-center`}>
                                                            <RadioButton
                                                                label={option.label}
                                                                name="abhaOptions"
                                                                checked={selectedABHAOption === option.value}
                                                                onChangeCb={() => handleSelectedABHAChange(option.value)}
                                                            />
                                                        </div>
                                                        
                                                    ))}
                                                </div>
                                                {selectedABHAOption && selectedABHAOption !== "Scan and Share" && (
                                                    <>
                                                        <Input
                                                            label={`Enter ${selectedABHAOption}`}
                                                            placeholder={`Please enter ${selectedABHAOption}`}
                                                            isRequired={true}
                                                            name={FORM_FIELDS_NAMES.AADHAR_NUMBER}
                                                            value={searchValue}
                                                            onChangeCb={(e) => {
                                                                setShowErrorText({show: false, message:""});
                                                                handleSearchValue(e.target.value);
                                                            }}
                                                            onBlurCb={handleBlur}
                                                        />
                                                        {(selectedABHAOption === "ABHA Number" || selectedABHAOption === "ABHA Address") &&
                                                            <div>
                                                                <Label fontSize="sm" fontWeight='bold' isRequired={true}>Send OTP To</Label>
                                                                <div className="flex flex-wrap gap-4 mt-2">
                                                                    {ABHA_VERIFICATION_OPTIONS.map((option) => (
                                                                        <div key={option.value} className={`border ${selectedOtpOption === option.value ? "border-blue-500 border-2":"border-gray-400"} p-2 rounded-lg `}>
                                                                            <RadioButton
                                                                                label={option.label}
                                                                                name='otpOptions'
                                                                                checked={selectedOtpOption === option.value}
                                                                                onChangeCb={() => handleSelectedOtpChange(option.value)}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        }  
                                                    </>
                                                )}
                                                {selectedABHAOption === "Scan and Share" && 
                                                    <img className='w-1/2 h-1/2' src={QRcode} alt='QRcode loading failed'></img>
                                                }
                                                <p> Don't have an Account? 
                                                <Label fontSize="md" color="blue-500" 
                                                    onChangeCb= {() => {
                                                        setOpenImportUsingAadhaarModal(true);
                                                        dispatch(setOpenGetOrCreatePatientModal("detailsByCreate"))
                                                        dispatch(setIsCreateABHA(true))
                                                    }} 
                                                    customClass='cursor-pointer'> Create account</Label>
                                                </p>
                                            </div>
                                        </div>   
                                    </div>
								</ModalComponent>
							</div>
						</Form>
                        { openValidateGetOtpModal &&
                            <ValidateOTPModal
                                open={openValidateGetOtpModal}
                                getVia={field}
                                otpOption = {selectedOtpOption}
                                searchValue = {selectedABHAOption === "Aadhar Number" ? searchValue.replace(/-/g, '') : searchValue}
                                aadhar = {selectedABHAOption === "Aadhar Number" ? searchValue.replace(/-/g, '') : null}
                                close={() => {
                                    dispatch(setOpenValidateGetOtpModal(false))
                                    close()

                                }}
                                modalType="getABHADetails"
                            />
                            
                        }

                        {
                            <ImportFromAadhaar
                                open={openImportUsingAadhaarModal}
                                close={() => setOpenImportUsingAadhaarModal(false)}
                            />
                        }
                        </>
					);
				}}
			</Formik>
			
		</>
    );
};

export default ImportFromABHA;
