import React, { useState } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { ABHA_HEALTH_RECORD_FIELDS, ABHA_HEALTH_RECORD_HI_TYPES, MEDICATION_REQUEST_INTENT, MEDICATION_REQUEST_STATUS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { v4 as uuidv4 } from 'uuid';
import Observation from "./observation";
import Icons from "../../../../../components/icons/Icons";
import HealthRecordHelper from "../utils/utils";

const MedicationRequest = ({onConfirm, close}) => {
    const [observationList, setObservationList] = useState([]);
    const [isObservationPopupOpen, setIsObservationPopupOpen] = useState(false);
    const [observationError, setObservationError] = useState("")

    const validate = (values) => {
        const errors = {};
        if (!values.medicationStatus || values.medicationStatus.value === "Select Status") {
          errors.medicationStatus = "Please select Medication request status.";
        }
        if (!values.medicationIntent || values.medicationIntent.value === "Select Intent") {
            errors.medicationIntent = "Please select Medication intent.";
          }
        if (!values.medicationText) {
            errors.medicationText = "Please provide a medication text";
        }
        if(!values.authorizedTime) {
            errors.authorizedTime = "Please provide a authorized time";
        }
        if(!values.dosageInstruction) {
            errors.dosageInstruction = "Please Provide dosage instruction"
        }
        return errors;
    };

    return(
        <>
            <Formik
                    initialValues={{
                        medicationStatus:{ label: "Select Status", value: "Select Status" },
                        medicationIntent: { label: "Select Intent" , value: "Select Intent"  },
                        medicationText:"",
                        authorizedTime:"",
                        medicationReason:"",
                        dosageInstruction:"",
                        additionalInstruction:"",
                        route:"",
                        method:""
                    }}
                validate={validate}
                onSubmit={(values) => {
                    const data = {
                        resourceType:ABHA_HEALTH_RECORD_FIELDS.MEDICATION_REQUEST.split(' ').join(''),
                        status: values.medicationStatus.value,
                        intent:values.medicationIntent.value,
                        medicationText: values.medicationText,
                        authoredOn:values.authorizedTime,
                        reasonCode: values.medicationReason,
                        reasonReference:observationList.map((item) => item.data),
                        dosageInstructionText: values.dosageInstruction,
                        additionalInstruction:values.additionalInstruction,
                        route: values.route,
                        method:values.method,
                    }
                    const formattedData = HealthRecordHelper.removeEmptyFields(data)
                    onConfirm((prev) => [...prev, {type: `Medication Report ${prev.length + 1}`, medicationRequestReport: formattedData}])
                    close()
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                    <div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl min-h-[35%] max-h-[90%] overflow-y-auto">
                        <div className="flex justify-between items-center border-b pb-3">
                            <h2 className="text-lg font-semibold">Medication Request</h2>
                            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                                <Icons iconName={"closeIcon"}/>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className=" grid grid-cols-2 gap-2">
                                <SelectDropdown
                                    label="Medication Status"
                                    name="medicationStatus"
                                    isRequired={true}
                                    customClasses="w-full"
                                    value={values.medicationStatus}
                                    options={[{ label: "Select Status", value: "Select Status" }, ...MEDICATION_REQUEST_STATUS]}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue("medicationStatus", selectedOption)
                                    }
                                />
                                <SelectDropdown
                                    label="Medication Intent"
                                    name="medicationIntent"
                                    isRequired={true}
                                    customClasses="w-full"
                                    value={values.medicationIntent}
                                    options={[{ label: "Select Intent", value: "Select Intent" }, ...MEDICATION_REQUEST_INTENT]}
                                    onChangeCb={(selectedOption) => setFieldValue("medicationIntent", selectedOption)}
                                />
                                <Input
                                    label="Medication Text"
                                    placeholder="Medication to be taken"
                                    type="text"
                                    name="medicationText"
                                    isRequired={true}
                                    value={values.medicationText}
                                    onChangeCb={(e) => setFieldValue("medicationText", e.target.value)}
                                />
                                <Input
                                    label="Authorized Time"
                                    name="authorizedTime"
                                    type="datetime-local"
                                    isRequired={true}
                                    value={values.authorizedTime}
                                    onChangeCb={(e) => setFieldValue("authorizedTime", e.target.value)}
                                />
                                <Input
                                    label="Reason"
                                    name="medicationReason"
                                    type="text"
                                    placeholder="Reason or indication for ordering or not ordering the medication"
                                    value={values.medicationReason}
                                    onChangeCb={(e) => setFieldValue("medicationReason", e.target.value)}
                                />
                                <div className="mt-2 col-span-2">
                                    <ItemManager
                                        key={`medicationObservation_${uuidv4()}`}
                                        title={"Reason reference"} 
                                        items={observationList}
                                        setItems={setObservationList}
                                        setIsPopupOpen={setIsObservationPopupOpen}
                                        customHeight="44"
                                    />
                                </div>
                                <Input
                                    label="Dosage Instruction"
                                    name="dosageInstruction"
                                    type="text"
                                    isRequired={true}
                                    placeholder="Free text dosage instructions e.g. SIG"
                                    value={values.dosageInstruction}
                                    onChangeCb={(e) => setFieldValue("dosageInstruction", e.target.value)}
                                />
                                <Input
                                    label="Additional Instruction"
                                    name="additionalInstruction"
                                    type="text"
                                    placeholder="Additionala dosage instructions"
                                    value={values.additionalInstruction}
                                    onChangeCb={(e) => setFieldValue("additionalInstruction", e.target.value)}
                                />
                                <Input
                                    label="Route"
                                    name="route"
                                    type="text"
                                    placeholder="How drug should enter body"
                                    value={values.route}
                                    onChangeCb={(e) => setFieldValue("route", e.target.value)}
                                />
                                <Input
                                    label="Method"
                                    name="method"
                                    type="text"
                                    placeholder="Dosage instruction method"
                                    value={values.method}
                                    onChangeCb={(e) => setFieldValue("method", e.target.value)}
                                />
                            </div>
                            <div className="flex justify-end">
                                <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
        {isObservationPopupOpen && 
            <Observation 
                key={`medication${uuidv4()}`}
                onConfirm={setObservationList} 
                setObservationError={setObservationError}
                close={()=> setIsObservationPopupOpen(false)}
                isNested= {true}
            />
        }
    </>
    )
}
export default MedicationRequest;