import React, { useState } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { ABHA_HEALTH_RECORD_FIELDS, OBSERVATION_STATUS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import ItemManager from "../ItemManager";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import { v4 as uuidv4 } from 'uuid';
import Icons from "../../../../../components/icons/Icons";
import Observation from "./observation";
import { getValidationSchema } from "../../../../../libs/formsUtils";

const OtherObservation = ({ onConfirm, close, title, exampleVal, codeable = false }) => {
    const [observationList, setObservationList] = useState([]);
    const [isObservationPopupOpen, setIsObservationPopupOpen] = useState(false);
    const name = title.split(" ").map((word, idx) => idx === 0 ? word.toLowerCase(): word).join('');
    
    const fields = [
        { fieldName: `${name}Status`, isRequired: true, isDropdown: true },
        { fieldName: `${name}Code`, isRequired: true },
        ...(codeable ? [] : [
            { fieldName: `${name}Result`, isRequired: true },
            { fieldName: `${name}Units`, isRequired: true },
        ])
    ];
    
    
    const validationSchema = getValidationSchema(fields);
    return (
        <>
            <Formik
                initialValues={{
                   [`${name}Status`]:"",
                   [`${name}Code`]: "",
                   [`${name}Result`]: "",
                   [`${name}Units`]: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    onConfirm((prev) => [
                        ...prev,
                        {
                            type: `${title} ${prev.length + 1}`,
                            data: {
                                resourceType: ABHA_HEALTH_RECORD_FIELDS.OBSERVATION,
                                status: values[`${name}Status`].value,
                                code: {
                                    text: values[`${name}Code`],
                                },
                                ...((codeable) ? {...(values[`${name}ValueCode`] && {
                                    valueCodeableConcept:{
                                    text: values[`${name}ValueCode`]
                                    }})
                                } :
                                {valueQuantity: {
                                    value: Number(values[`${name}Result`]),
                                    unit: values[`${name}Units`]
                                }}),
                                ...(observationList.length > 0 ? {hasMember: observationList.map((item) => item.data)} : {})
                            }
                        }
                    ]);
                    close();
                }}
            >
                {({
                    values, handleSubmit, setFieldValue, handleBlur,
                }) => (
                    <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                        <div className="bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl max-h-screen ">
                            <div className="flex justify-between items-center border-b pb-3">
                                <h2 className="text-lg font-semibold">Add {title}</h2>
                                <div className="flex justify-end items-center pb-3 hover:cursor-pointer" onClick={close}>
                                    <Icons iconName={"closeIcon"}/>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                                <div className="grid grid-cols-2 gap-4 mt-2">
                                    <SelectDropdown
                                        label="Select Status"
                                        isRequired={true}
                                        placeholder="Select"
                                        name= {`${name}Status`}
                                        customClasses="w-full"
                                        value={values[`${name}Status`]}
                                        options={OBSERVATION_STATUS}
                                        onChangeCb={(selectedOption) =>
                                            setFieldValue(`${name}Status`, selectedOption)
                                        }
                                    />
                                    
                                    <Input
                                        label="Code"
                                        placeholder= {`Enter Type of observation (code / type) Ex: ${exampleVal.code}`}
                                        name={`${name}Code`}
                                        isRequired={true}
                                        value={values[`${name}Code`]}
                                        onChangeCb={(e) => setFieldValue(`${name}Code`, e.target.value)}
                                        onBlurCb={handleBlur}
                                    />
                                    {!codeable ? 
                                    <>
                                        <Input
                                            label="Value"
                                            type="number"
                                            placeholder="Enter actual result"
                                            isRequired={true}
                                            name={`${name}Result`}
                                            value={values[`${name}Result`]}
                                            onChangeCb={(e) => setFieldValue(`${name}Result`, e.target.value)}
                                        />
                                        
                                        <Input
                                            label="Value Units"
                                            placeholder={`Please provide units for value. Ex: ${exampleVal.unit}`}
                                            isRequired={true}
                                            name={`${name}Units`}
                                            value={values[`${name}Units`]}
                                            onChangeCb={(e) => setFieldValue(`${name}Units`, e.target.value)}
                                        />
                                    </> :
                                        <Input
                                            label="Value Code/text"
                                            placeholder={`Please provide code/text. Ex: ${exampleVal.unit}`}
                                            name={`${name}ValueCode`}
                                            value={values[`${name}ValueCode`]}
                                            onChangeCb={(e) => setFieldValue(`${name}ValueCode`, e.target.value)}
                                        /> 
                                    }
                                    <ItemManager
                                        key={"Related resource that belongs to the Observation group"}
                                        title={"Related resource that belongs to the Observation group"}
                                        items={observationList}
                                        setItems={setObservationList}
                                        setIsPopupOpen={setIsObservationPopupOpen}
                                        customHeight="44"
                                        customClass={"col-span-2"}
                                    />
                                </div>
                                <div className="flex justify-end">
                                    <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Formik>
            {isObservationPopupOpen && 
                <Observation
                    key={`nestedObservation_${uuidv4()}`} 
                    onConfirm={setObservationList} 
                    close={() => setIsObservationPopupOpen(false)}
                    items={observationList}
                />
            }
        </>
    );
};

export default OtherObservation;
