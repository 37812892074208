import AppDataService from './AppDataService'

const COMMON_BASE = 'patients'

export default class PatientDataService {

    static async getPatientGridList(params) {
        return await AppDataService.get(`${COMMON_BASE}`, params)
    }

    static async createNewPatient(data) {
        return await AppDataService.post(`${COMMON_BASE}`, data)
    }

    static async savePatientPreferences(uuid, data) {
        return await AppDataService.post(`${COMMON_BASE}/${uuid}/preferences`, data)
    }

    static async updatePatientDetails(uuid, data) {
        return await AppDataService.patch(`${COMMON_BASE}/${uuid}`, data)
    }

    static async getPatientDetails(uuid, params) {
        return await AppDataService.get(`${COMMON_BASE}/${uuid}`, { params })
    }

    static async generateToken(id) {
        return await AppDataService.get(`abha/m2/link/token/${id}`)
    }
    static async careContextLinking(data) {
        return await AppDataService.post(`abha/m2/hip/linking`, data);
    }
    static async hipNotify(data) {
        return await AppDataService.post(`abha/m2/consent/hip/notify`, data);
    }
    static async acknowledgeABDM(data) {
        return await AppDataService.post(`abha/m2/hip/on-request`, data);
    }
    static async dataPushToABDM(data) {
        return await AppDataService.post(`abha/m2/data/push`, data);
    }
    static async healthInformationNotify(data) {
        return await AppDataService.post(`abha/m2/health-information/notify`, data);
    }
    static async createDiagnosticBundle(data) {
        return await AppDataService.post(`health-info/diagnosis-report`, data);
    }
    static async createDischargeSummaryBundle(data) {
        return await AppDataService.post(`health-info/discharge-summary`, data);
    }
    static async createHealthDocumentBundle(data) {
        return await AppDataService.post(`health-info/health-doc`, data);
    }
    static async createOpConsultationBundle(data) {
        return await AppDataService.post(`health-info/op-consult`, data);
    }
    static async createPrescriptionBundle(data) {
        return await AppDataService.post(`health-info/prescription`, data);
    }
    static async createWellnessBundle(data) {
        return await AppDataService.post(`health-info/wellness`, data);
    }
    static async createImmunizationBundle(data) {
        return await AppDataService.post(`health-info/immunization`, data);
    }
    static async createInvoiceBundle(data) {
        return await AppDataService.post(`health-info/invoice`, data);
    }
    static async pushBundle(data) {
        return await AppDataService.post(`abha/m2/care-context-link`, data);
    }
    static async retryDataPush(data) {
        return await AppDataService.post(`abha/m2/push/data/retry`, data);
    }
    static async consentIdRequest(data) {
        return await AppDataService.post(`abha/m3/request/init`, data);
    }
    static async consentRequestStatus(data) {
        return await AppDataService.post(`abha/m3/request/status`, data);
    }
    static async getDecryptedData(data) {
        return await AppDataService.post(`ecdh/decrypt`, data)
    }
    static async invitePortalAccess(uuid) {
        return await AppDataService.post(`${COMMON_BASE}/${uuid}/invite`, {})
    }

    static async resendPortalAccess(uuid) {
        return await AppDataService.post(`${COMMON_BASE}/${uuid}/invite/resend`, {})
    }

    static async revokedPortalAccess(uuid) {
        return await AppDataService.post(`${COMMON_BASE}/${uuid}/invite/disable`, {})
    }
    static async upcomingbirthdays() {
        return await AppDataService.get(`${COMMON_BASE}/upcomingbirth`,)
    }
    static async validateAadhaar(data) {
        return await AppDataService.post(`${COMMON_BASE}/validate/aadhar`,data)
    }
    static async getExistingABHADetails(data) {
        const queryString = new URLSearchParams(data).toString();
        return await AppDataService.get(`abha?${queryString}`);
    }
    static async abhaIntAadhaarValidation(data) {
        return await AppDataService.post(`abha/send/otp`,data)
    }
    static async abhaUpdateValidation(data) {
        return await AppDataService.post(`abha/request/otp`, data)
    }
    static async otpVerification(data) {
        return await AppDataService.post(`abha/verify/otp`,data)
    }
    static async createABHAPatien(data) {
        return await AppDataService.post(`abha/create/patient`, data)
    }
    static async abhaUpdateOtpVerification(data) {
        return await AppDataService.post(`abha/update/details`, data)
    }
    static async abhaAddressSuggetions(data){
        return await AppDataService.post(`abha/suggest/address`,data)
    }
    static async abhaAddressUpdate(data){
        return await AppDataService.post(`abha/change/address`,data)
    }
    static async abhaProfilePictureUpdate(data){
        return await AppDataService.patch(`abha/update/picture`, data)
    }
    static async downloadCard(data){
        return await AppDataService.post(`abha/card`,data)
    }
}