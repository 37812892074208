import store from '../../../store/store';

export const componentKey = 'PATIENTDASHBOARD_SECTION';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setPatientId: (state, action) => {
			state.currentPatientId = action.payload;
		},
		setPatientData: (state, action) => {
			state.patientData = action.payload;
		},
		setIsOpenDeletePopup: (state, action) => {
			state.isOpenDeletePopup = action.payload;
		},
		setSelectedBundle: (state, action) => {
			state.selectedBundle = action.payload
		},
		setBundlePushResponse: (state, action) => {
			state.bundlePushResponse = action.payload
		},
		setBundlePushError: (state, action) => {
			state.bundlepushError = action.payload
		},
		setConsetRequestData: (state, action) => {
			state.consentRequestData = action.payload
		},
		setHUInotifyData: (state, action) => {
			state.huiNotifyData = action.payload
		},
		setDecryptedData: (state, action) => {
			state.decryptedData = action.payload
		},
		setBundlesData: (state, action) => {
			state.bundlesData = action.payload
		}
	},
	initialReducerState: {
		currentPatientId: '',
		patientData: {},
		isOpenDeletePopup: false,
		selectedBundle: null,
		bundlePushResponse: {},
		bundlepushError: {},
		consentRequestData:null,
		huiNotifyData: null,
		decryptedData: null,
		bundlesData: null,
	},
});

export const { setPatientId, setPatientData, setIsOpenDeletePopup, setSelectedBundle, setBundlePushResponse, setBundlePushError, setConsetRequestData, setHUInotifyData, setDecryptedData, setBundlesData } = actions;
