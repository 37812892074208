import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { ENCOUNTER_FIELDS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import ItemManager from "../ItemManager";
import Condition from "./Condition";
import Label from "../../../../../components/common-components/label/Label";
import HealthRecordHelper from "../utils/utils";

const EncounterDiagnosis = ({ open, close, onConfirm }) => {
    const [conditionsList, setConditionsList] = useState([]);
    const [conditionError, setConditionError] = useState("");
    const [isConditionPopupOpen, setIsConditionPopupOpen] = useState(false);

    useEffect(() => {
        if(conditionsList.length !== 0) setConditionError("");

    }, [conditionsList])

    return(
        <>
            <Formik
                initialValues={{
                    [ENCOUNTER_FIELDS.DIAGNOSIS_USE]: "",
                }}
                onSubmit={(values) => {
                    if(conditionsList.length === 0) {
                        setConditionError("Atleast one codition is required")
                        return;
                    }
                    const data= {
                        condition: conditionsList.map((item) => item.data)[0],
                        use: values[ENCOUNTER_FIELDS.DIAGNOSIS_USE],
                    }
                    const formattedData = HealthRecordHelper.removeEmptyFields(data)
                    onConfirm((prev) => [...prev, {type: `Diagnosis ${prev.length + 1}`, data: formattedData}])
                    close()
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                 <ModalComponent
                    open={open}
                    title={"Diagnosis"}
                    footerButton={
                        <Button
                            variant={BUTTON_VARIANTS.CONTAINED}
                            onClickCb={() => handleSubmit()}
                        >
                            Submit
                        </Button>
                    }
                    close={() => close()}
                    customClasses="w-[90%] max-w-[800px]"
                >
                    <div className="flex flex-col gap-4">
                        <div>
                            <ItemManager
                                key={`encounterCondition`}
                                title={
                                        <>
                                        Condition <span className="text-red-500">*</span>
                                        </>
                                    }
                                items={conditionsList}
                                setItems={setConditionsList}
                                setIsPopupOpen={(value) => {
                                    if(conditionsList.length < 1){
                                        setIsConditionPopupOpen(value)
                                    }else setConditionError("Only one encounter is allowed")
                                    
                                }}
                                customHeight="52"
                            />
                            {conditionError && (
                                <Label fontSize="sm" fontWeight="bold" color="red-500">
                                    {conditionError}
                                </Label>
                            )}
                        </div>
                        <Input
                            label="Use"
                            placeholder="Role that this diagnosis has within the encounter (e.g. admission, billing, discharge …)"
                            name={ENCOUNTER_FIELDS.DIAGNOSIS_USE}
                            value={values[ENCOUNTER_FIELDS.DIAGNOSIS_USE]}
                            onChangeCb={(e) => {
                                setFieldValue(
                                    ENCOUNTER_FIELDS.DIAGNOSIS_USE,
                                    e.target.value
                                );	
                            }}
                        />
                    </div>
                </ModalComponent>
            )}
        </Formik>
        {isConditionPopupOpen &&
            <Condition
                title={"Condition"}
                value="Identification of the condition, problem or diagnosis"
                open={isConditionPopupOpen}
                onConfirm={setConditionsList}
                close={() => setIsConditionPopupOpen(false)}
            />
        }
    </>
    )
}
export default EncounterDiagnosis;