import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { INVOICE_RECORD_FIELDS, RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey, setBundlePushError, setBundlePushResponse, setSelectedBundle } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { createInvoiceBundle, pushBundle, retryDataPush } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
import Invoice from "./Invoice";
import HealthRecordHelper from "../utils/utils";
import LoadingPopup from "../LoadingPopup";
import Encounter from "./Encounter";

// const fields = [
// 	{ fieldName: INVOICE_RECORD_FIELDS.STATUS, isRequired: true, isDropdown: true },
//     { fieldName: INVOICE_RECORD_FIELDS.TITLE, isRequired: true },
//     { fieldName: INVOICE_RECORD_FIELDS.DATE, isRequired: true },
// ];

// const validationSchema = getValidationSchema(fields);
const InvoiceRecord = ({ handleCancle, payloadHelper, title, setTitleError, setTitleSubmitted }) => {
    const { patientData, selectedBundle, bundlepushError } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [reportsError, setReportsError] = useState("");
    const [loadingpopup, setLoadingPopup] = useState(false);
    const [isLinkingFailed, setIsLinkingFailed] = useState(false);
    const [encounterItem, setEncounterItem] = useState([]);
    const [isEncounterPopupOpen, setIsEncounterPopupOpen] = useState(false);
    const [encounterError, setEncounterError] = useState("");
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if(selectedBundle){
            const payload = {
                ...payloadHelper,
                patientId: patientData?.uuid,
                bundleData: selectedBundle,
            }
            if(isLinkingFailed) {
                payload.previous = true;
                dispatch(retryDataPush(payload))
            } else {
                dispatch(pushBundle(payload));
            }
            setLoadingPopup(true)
        }
    },[dispatch, selectedBundle])

    useEffect(() => {
        if(bundlepushError?.statusCode){
            setLoadingPopup(false);
            setIsLinkingFailed(true)
            dispatch(setSelectedBundle(null))
            dispatch(setBundlePushError({}))
        }
    }, [bundlepushError, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setSelectedBundle(null))
            dispatch(setBundlePushResponse({}))
            dispatch(setBundlePushError({}))
        }
    }, [dispatch])
        
    useEffect(() => {
        if(invoiceList.length > 0) setReportsError("");
        if(encounterItem.length > 0) setEncounterError("");
    },[invoiceList, encounterItem])

    const structurePayloadWithAllData = (values) => {
        const payload = {
            status: values[INVOICE_RECORD_FIELDS.STATUS].value,
            subject: HealthRecordHelper.getStructurePatientData(patientData),
            date: values[INVOICE_RECORD_FIELDS.DATE],
            title: values[INVOICE_RECORD_FIELDS.TITLE],
            encounter: encounterItem[0]?.data,
            section: {
                text: values[INVOICE_RECORD_FIELDS.SUMMARY],
                entry: invoiceList.map((item) => item.data),
            }
        };
        return payload;
    };

        const validate = (values) => {
            const errors = {};
            if (!values[INVOICE_RECORD_FIELDS.STATUS]?.value) {
                errors[INVOICE_RECORD_FIELDS.STATUS] = "Please select a composition status.";
            }
            if (!values[INVOICE_RECORD_FIELDS.TITLE]) {
                errors[INVOICE_RECORD_FIELDS.TITLE] = "Please provide title";
            }
            if (!values[INVOICE_RECORD_FIELDS.DATE]) {
                errors[INVOICE_RECORD_FIELDS.DATE] = "Please provide date";
            }
            if(!title){
                setTitleError("Please provide the title")
            }
            if (encounterItem.length === 0) {
                setEncounterError("Encounter entry is required.");
            }
            const payload = structurePayloadWithAllData(values);
            if(invoiceList.length === 0) {
                setReportsError("Atleast 1 entry is required")
            }
            return errors;
        };

    return (
        <>
            <Formik
                initialValues={{
                    [INVOICE_RECORD_FIELDS.STATUS]:"",
                    [INVOICE_RECORD_FIELDS.DATE]:"",
                    [INVOICE_RECORD_FIELDS.TITLE]:"",
                    [INVOICE_RECORD_FIELDS.SUMMARY]:"",
                }}
                validate={validate}
                onSubmit={(values) => {
                    dispatch(setSelectedBundle(null));
                    if(invoiceList.length === 0 || encounterItem.length === 0) {
                        return;
                    }
                    const payload = HealthRecordHelper.removeEmptyFields(structurePayloadWithAllData(values));
                    dispatch(createInvoiceBundle(payload));
                }}
            >
                {({ values, errors, setFieldValue, handleSubmit }) => (
                    <form 
                        onSubmit={(e) => {
                            setTitleSubmitted(true)
                            setSubmitAttempted(true)
                            handleSubmit(e);
                        }} 
                        className="space-y-4 h-full w-full flex flex-col justify-between"
                    >
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            <SelectDropdown
                                label="Composition Status"
                                name={INVOICE_RECORD_FIELDS.STATUS}
                                isRequired={true}
                                placeholder="Select"
                                customClasses="w-full"
                                value={values[INVOICE_RECORD_FIELDS.STATUS]}
                                options={RECORD_STATUS}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue(INVOICE_RECORD_FIELDS.STATUS, selectedOption)
                                }
                            />
                            <Input
                                label="Invoice Editing Time"
                                name={INVOICE_RECORD_FIELDS.DATE}
                                type="datetime-local"
                                isRequired={true}
                                value={values[INVOICE_RECORD_FIELDS.DATE]}
                                onChangeCb={(e) => setFieldValue(INVOICE_RECORD_FIELDS.DATE, e.target.value)}
                            />
                            <Input
                                label="Title"
                                placeholder="Human Readable name/title"
                                type="text"
                                name={INVOICE_RECORD_FIELDS.TITLE}
                                isRequired={true}
                                value={values[INVOICE_RECORD_FIELDS.TITLE]}
                                onChangeCb={(e) => setFieldValue(INVOICE_RECORD_FIELDS.TITLE, e.target.value)}
                            />
                            <Input
                                label="Summary"
                                placeholder="Text summary of the section, for human interpretation"
                                type="text"
                                name={INVOICE_RECORD_FIELDS.SUMMARY}
                                value={values[INVOICE_RECORD_FIELDS.SUMMARY]}
                                onChangeCb={(e) => setFieldValue(INVOICE_RECORD_FIELDS.SUMMARY, e.target.value)}
                            />
                             <div className="flex flex-col">
                                    <ItemManager
                                        key={`op_encounter_entry_immunization`}
                                        title="Encounter entry"
                                        isRequired = {true}
                                        items={encounterItem}
                                        setItems={setEncounterItem}
                                        setIsPopupOpen={(value) => {
                                            if(encounterItem.length < 1){
                                                setIsEncounterPopupOpen(value)
                                            }else setEncounterError("Only one encounter is allowed")
                                            
                                        }}
                                        customHeight="52"
                                    />
                                    {encounterError && submitAttempted && (
                                        <Label fontSize="md" color="red-500">
                                            {encounterError}
                                        </Label>
                                    )}
                                </div>
                            <div className="flex flex-col">
                                <ItemManager
                                    title={<>Invoice entry <span className="text-red-500">*</span></>} 
                                    items={invoiceList}
                                    setItems={setInvoiceList}
                                    setIsPopupOpen={setIsPopupOpen}
                                    customHeight="52"
                                />
                                {reportsError && <Label fontSize="md" color="red-500">{reportsError}</Label>}
                            </div>
                            
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button 
                                onClickCb={handleCancle} 
                                variant={BUTTON_VARIANTS.OUTLINED}
                            >Cancel</Button>
                            <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                {isLinkingFailed ? "Try Again" : "Link to ABDM"}
                            </Button>
                        </div>
                    </form>
                )}
                </Formik>
                {isEncounterPopupOpen && 
                    <Encounter
                        open={isEncounterPopupOpen}
                        onConfirm={setEncounterItem}
                        close={() => setIsEncounterPopupOpen(false)}
                    />
                }
                {isPopupOpen && 
                    <Invoice 
                        onConfirm={setInvoiceList} 
                        close={() => setIsPopupOpen(false)}
                    />
                }
                <LoadingPopup
                    isOpen={loadingpopup} 
                    handleCancle = {() => {
                        setLoadingPopup(false)
                        handleCancle()
                    }}
                    handleClose = {() => setLoadingPopup(false)}
                />
        </>
    );
};

export default InvoiceRecord;
