export const ABHA_HEALTH_RECORD_FIELDS = {
    DIAGNOSTIC_REPORT_lAB: "Diagnostic Report Lab",
    MEDICATION_REQUEST: "Medication Request",
    OBSERVATION:"Observation",
    ENCOUNTER_CLASSIFICATION: "Encounter Classification",
    ENCOUNTER_TYPE: "Encounter Type",
    ENCOUNTER_SERVICE_TYPE: "Encounter Service Type",
    ENCOUNTER_PRIORITY: "Encounter Priority",
    ENCOUNTER_PERIOD_START: "Encounter Period start",
    ENCOUNTER_PERIOD_END: "Encounter Period end",
    ENCOUNTER_REASON: "Encounter Reason",
    ENCOUNTER_DIAGNOSIS_USE: "Encounter Diagnosis Use",
    DISPLAY_NAME: "displayName",
}
export const ABHA_HEALTH_RECORD_NAVS = {
    DIAGNOSTIC_REPORT: "DiagnosticReport",
    DISCHARGE_SUMMARY: "DischargeSummary",
    HEALTH_DOCUMENT_RECORD: "HealthDocumentRecord",
    OP_CONSULTATION: "OPConsultation",
    IMMUNIZATION_RECORD:"ImmunizationRecord",
    PRESCRIPTION: "Prescription",
    WELLNESS_RECORD: "WellnessRecord",
    INVOICE: "Invoice",
}
export const ABDM_PROFILES = [
    {title: "Diagnostic", icon: "diagnosticIcon", value: "DiagnosticReport"},
    {title: "Discharge Summary", icon: "dischargeIcon", value: "DischargeSummary"},
    {title: "OP Consultation", icon: "opconsultIcon", value: "OPConsultation"},
    {title: "Prescription", icon: "prescriptionIcon", value: "Prescription"},
    {title: "Wellness", icon: "wellnessIcon", value: "WellnessRecord"},
    {title: "Immunization", icon: "immunizationIcon", value: "ImmunizationRecord"},
    {title: "Invoice", icon: "invoiceIcon", value: "Invoice"},
    {title: "Health Document", icon: "documentRefIcon", value: "HealthDocumentRecord"},
]

export const ABHA_HEALTH_RECORD_VIEW_NAVS = {
    CONSENT_INIT_REQUEST: "Consent Init Request",
    CONSENT_STATUS: "Consent Status",
    VIEW_RECORDS: "View Records",
}

export const ABHA_HEALTH_RECORD_HI_TYPES = {
    DIAGNOSTIC_REPORT: "Diagnostic Report",
    DISCHARGE_SUMMARY: "Discharge Summary",
    HEALTH_DOCUMENT_RECORD: "Health Document Record",
    OP_CONSULTATION: "OP Consultation",
    PRESCRIPTION: "Prescription",
    WELLNESS_RECORD: "Wellness Record",
    IMMUNIZATION_RECORD: "Immunization Record",
    INVOICE: "Invoice",
};

export const RECORD_STATUS = [
    {label: "Preliminary", value: "preliminary"},
    {label: "Final", value: "final"},
    {label: "Amended", value: "amended"},
    {label: "Entered-in-error", value: "entered-in-error"},
]

export const FORM_OBSERVATION_FIELDS = {
    STATUS: "status",
    CODE: "observationCode",
    VALUE: "observationValue",
    UNITS: "observationUnits",
}

export const MEDICATION_REQUEST_STATUS = [
    { label: "Active", value: "active" },
    { label: "On Hold", value: "on-hold" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Completed", value: "completed" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Stopped", value: "stopped" },
    { label: "Draft", value: "draft" },
    { label: "Unknown", value: "unknown" },
];

export const MEDICATION_REQUEST_INTENT = [
    { label: "Proposal", value: "proposal" },
    { label: "Plan", value: "plan" },
    { label: "Order", value: "order" },
    { label: "Original Order", value: "original-order" },
    { label: "Reflex Order", value: "reflex-order" },
    { label: "Filler Order", value: "filler-order" },
    { label: "Instance Order", value: "instance-order" },
    { label: "Option", value: "option" },
];
  

export const RECORD_TYPES = [
    {label: "Diagnostic Report Lab", value: "Diagnostic Report Lab"},
    // {label: "Diagnostic Report Imaging ", value: "Diagnostic Report Imaging "},
    // {label: "Document Reference", value: "Document Reference"},
]

export const DIAGNOSTIC_REPORT_lAB_STATUS = [
    {label: "Registered", value: "registered"},
    {label: "Partial", value: "partial"},
    {label: "Preliminary", value: "preliminary"},
    {label: "Final", value: "final"},
]

export const OBSERVATION_STATUS = [
    {label: "Registered", value: "registered"},
    {label: "Final", value: "final"},
    {label: "Preliminary", value: "preliminary"},
    {label: "Amended", value: "amended"},
]
export const ENCOUNTER_FIELDS = {
    DIAGNOSIS_USE: "diagnosisUse"
}
export const ENCOUNTER_STATUS = [
    { label: "Planned", value: "planned" },
    { label: "Arrived", value: "arrived" },
    { label: "Triaged", value: "triaged" },
    { label: "In Progress", value: "in-progress" },
    { label: "On Leave", value: "onleave" },
    { label: "Finished", value: "finished" },
    { label: "Cancelled", value: "cancelled" },
];

export const ENCOUNTER_CLASSIFICATION_OPTIONS = [
    { label: "Ambulatory", value: "AMB" },
    { label: "Emergency", value: "EMER" },
    { label: "Field", value: "FLD" },
    { label: "Home health", value: "HH" },
    { label: "Inpatient encounter", value: "IMP" },
    { label: "Inpatient acute", value: "ACUTE" },
    { label: "Inpatient non-acute", value: "NONAC" },
    { label: "Observation encounter", value: "OBSENC" },
    { label: "Pre-admission", value: "PRENC" },
    { label: "Short stay", value: "SS" },
    { label: "Virtual", value: "VR" }
  ];

export const DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE = {
    CLINICAL_STATUS: "clinicalStatus",
    CODE: "allergyIntoleranceCode"
}

export const CLINICAL_STATUS_OPTIONS = [
    { "label": "Active", "value": "active" },
    { "label": "Inactive", "value": "inactive" },
    { "label": "Resolved", "value": "resolved" }
]

export const FAMILY_MEMBER_HISTORY_STATUS = [
    {label: "Partial", value: "partial"},
    {label: "Completed", value: "completed"},
    {label: "Entered-in-error ", value: "entered-in-error "},
    {label: "Health-unknown", value: "health-unknown"},
]
export const PROCEDURE_STATUS = [
    { label: "Preparation", value: "preparation" },
    { label: "In Progress", value: "in-progress" },
    { label: "Not Done", value: "not-done" },
    { label: "On Hold", value: "on-hold" },
    { label: "Stopped", value: "stopped" },
    { label: "Completed", value: "completed" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Unknown", value: "unknown" },
];

export const CARE_PLAN_STATUS = [
    { label: "Draft", value: "draft" },
    { label: "Active", value: "active" },
    { label: "On Hold", value: "on-hold" },
    { label: "Revoked", value: "revoked" },
    { label: "Completed", value: "completed" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Unknown", value: "unknown" }
]

export const CARE_PLAN_INTENT = [
    { label: "Proposal", value: "proposal" },
    { label: "Plan", value: "plan" },
    { label: "Order", value: "order" },
    { label: "Option", value: "option" }
]

export const GOAL_STATUS_OPTIONS = [
    { label: "Proposed", value: "proposed" },
    { label: "Planned", value: "planned" },
    { label: "Accepted", value: "accepted" },
    { label: "Active", value: "active" },
    { label: "On Hold", value: "on-hold" },
    { label: "Completed", value: "completed" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Unknown", value: "unknown" }
];

export const DOCUMENT_STATUS_OPTIONS = [
    { label: "Current", value: "current" },
    { label: "Superseded", value: "superseded" },
    { label: "Entered in Error", value: "entered-in-error" },
];

export const FORM_FIELDS_FOR_HEALTH_DOC = {
    STATUS:"healthDocStatus",
    DATE:"healthDocdate",
    TITLE:"healthDocTitle",
    DOC_REF:"healthDocRef"
}

export const FORM_FIELDS_FOR_CONSENT_INIT = {
    FROM: "dateFrom",
    TO: "dateTo",
}

export const FORM_FIELDS_FOR_OP_CONSULTATION = {
    STATUS:"opConsultationStatus",
    ENCOUNTER:"encounter",
    DATE:"dateTime",
    TITLE:"opConsultationTitle",
    CHIEF_COMPLAINTS:"opConsultationChiefComplaints",
    PHYSICAL_EXAMINATION:"physicalExamination",
    ALLERGIES:"allergies",
    MEDICAL_HISTORY:"medicalHistory",
    FAMILY_HISTORY:"familyHistory",
    INVESTIGATION_ADVICE:"investigationAdvice",
    MEDICATIONS:"Medications",
    FOLLOW_UP:"followUp",
    PROCEDURE:"procedure",
    REFERRAL:"referral",
    OTHER_OBSERVATIONS:"otherObservation",
    DOCUMENT_REFERENCE:"documentReference"
}

export const FORM_FIELDS_FOR_SERVICE_REQUEST = {
    TEXT: "textSummary",
    STATUS:"serviceRequestStatus",
    INTENT:"serviceRequestIntent",
    CODE:"serviceRequestcode"
}
export const SERVICE_REQUEST_STATUS_OPTIONS = [
    { label: "Draft", value: "draft" },
    { label: "Active", value: "active" },
    { label: "On Hold", value: "on-hold" },
    { label: "Revoked", value: "revoked" },
    { label: "Completed", value: "completed" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Unknown", value: "unknown" }
]

export const SERVICE_REQUEST_INTENT_OPTIONS = [
    { label: "Proposal", value: "proposal" },
    { label: "Plan", value: "plan" },
    { label: "Directive", value: "directive" },
    { label: "Order", value: "order" },
    { label: "Original Order", value: "original-order" },
    { label: "Reflex Order", value: "reflex-order" },
    { label: "Filler Order", value: "filler-order" },
    { label: "Instance Order", value: "instance-order" },
    { label: "Option", value: "option" }
]
  
export const FORM_FIELDS_FOR_APPOINTMENT = {
    STATUS: "appointmentStatus",
    SERVICE_CATEGORY: "serviceCategory",
    SERVICE_TYPE: "seriveType",
    SPECIALITY: "speciality",
    APPOINTMENT_TYPE: "appointmentType",
    REASON_CODE: "reasonCode",
    START: "appointmentStart",
    END: "appointmentEnd",
    PARTICIPANT_STATUS: "participantStatus",
    PARTICIPANT_TYPE: "participantType",

}

export const APPOINTMENT_STATUS_OPTIONS = [
    { label: "Proposed", value: "proposed" },
    { label: "Pending", value: "pending" },
    { label: "Booked", value: "booked" },
    { label: "Arrived", value: "arrived" },
    { label: "Fulfilled", value: "fulfilled" },
    { label: "Cancelled", value: "cancelled" },
    { label: "No Show", value: "noshow" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Checked In", value: "checked-in" },
    { label: "Waitlist", value: "waitlist" },
];

export const APPOINTMENT_PARTICIPANT_STATUS_OPTIONS = [
    { label: "Accepted", value: "accepted" },
    { label: "Declined", value: "declined" },
    { label: "Tentative", value: "tentative" },
    { label: "Needs Action", value: "needs-action" },
];

  
export const FORM_FIELDS_FOR_WELLNESS = {
    STATUS:"wellnessStatus",
    DATE:"dateTime",
    TITLE:"wellnessTitle",
}

export const FORM_FIELDS_FOR_OTHER_OBSERVATION = {
    STATUS: "observationStatus",
    CODE:"ObservationCode",
    VALUE_QUANTITY: "observationValueQunatity",
    VALUE_STRING: "",
    VALUE_CODEABLE:""
}

export const IMMUNIZATION_STATUS_OPTIONS = [
    { label: "Completed", value: "completed" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Not Done", value: "not-done" }
];

  
export const FORM_FIELDS_FOR_IMMUNIZATION = {
    STATUS:"immunizationStatus",
    VACCINE:"immunizationVaccine",
    OCCURRENCE:"immunizationOccurrence",
    LOT_NUMBER:"immunizationLotNumber",
    EXPIRATION_DATE:"immunizationExpirationDate",
    SITE:"immunizationSite",
    ROUTE:"immunizationRoute",
    REASON_CODE:"immunizationReason",
    DOSE_NUMBER:"immunizationDoseNumber"
}

export const FORM_FIELDS_FOR_ORGANIZATION = {
    NAME:"organizationName",
    TELECOM_SYSTEM:"telecomSystem",
    TELECOM_USE:"telecomUse",
    TELECOM_VALUE:"telecomValue",
    ADDRESS_TEXT:"addressText",
    ADDRESS_USE: "addressUse",
}

export const TELECOM_SYSTEM_OPTIONS = [
    { label: "Phone", value: "phone" },
    { label: "Fax", value: "fax" },
    { label: "Email", value: "email" },
    { label: "Pager", value: "pager" },
    { label: "URL", value: "url" },
    { label: "SMS", value: "sms" },
    { label: "Other", value: "other" }
];
  
export const TELECOM_USE_OPTIONS = [
    { label: "Home", value: "home" },
    { label: "Work", value: "work" },
    { label: "Temporary", value: "temp" },
    { label: "Old", value: "old" },
    { label: "Mobile", value: "mobile" }
];
  
export const LOCATION_STATUS_OPTIONS = [
    { label: "Active", value: "active" },
    { label: "Suspended", value: "suspended" },
    { label: "Inactive", value: "inactive" }
];

  
export const FORM_FIELDS_FOR_LOCATION = {
    STATUS: "locationStatus",
    NAME:"locationName",
    TELECOM_SYSTEM:"telecomSystem",
    TELECOM_USE:"telecomUse",
    TELECOM_VALUE:"telecomValue",
    ADDRESS_TEXT:"addressText"
}

export const ADDRESS_USE_OPTIONS = [
    { label: "Home", value: "home" },
    { label: "Work", value: "work" },
    { label: "Temporary", value: "temp" },
    { label: "Old", value: "old" },
    { label: "Billing", value: "billing" },
];

  
export const IMMUNIZATIO_RECORD = {
    STATUS: "immunizationCompositionStatus"
}

export const IMMUNIZATION_RECOMMENDATION_FIELDS = {
    DATE:"date",
    VACCINE_CODE: "vaccineCode",
    TARGET_DISEASE: "targetDisease",
    CONTRAINDICATED_VACCINE_CODE: "contraindicatedVaccineCode",
    FORCAST_STATUS: "forecastStatus",
    FORECAST_REASON:"forecastReason",
}

export const INVOICE_RECORD_FIELDS = {
    STATUS: "invoiceStatus",
    DATE: "invoiceDate",
    TITLE: "invoiceTitle",
    SUMMARY: "invoiceSummary"
}

export const FORM_FIELDS_FOR_INVOICE = {
    STATUS: "status",
    IDENTIFIER_TYPE: "invoiceIdentifierType",
    INVOICE_TYPE: "invoiceType",
    DATE: "date",
    CHARGE_CODE: "chargeCode",
    QUANTITY: "quantity",
    UNITS: "units",
    OCCURRENCE: "occurrence",
    PRICE_TEXT: "priceText",
    AMOUNT: "amount",
    CURRENCY: "currency",
    CHARGE_ITEM_STATUS: "status",
    CHARGE_ITEM_PRODUCT: "product",
    PRICE_COMPONENT_TYPE: "type",
    PRICE_COMPONENT_CODE: "priceComponentText",
    TOTAL_NET:"totalNet",
    TOTAL_GROSS: "totalGross",
}

export const INVOICE_STATUS = [
    { label: "Draft", value: "draft" },
    { label: "Issued", value: "issued" },
    { label: "Balanced", value: "balanced" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Entered in Error", value: "entered-in-error" }
];

export const INVOIE_TYPE_OPTIONS = [
    { label: "Consultation", value: "00" },
    { label: "Pharmacy", value: "01" },
    { label: "IPD", value: "02" },
    { label: "OPD", value: "03" },
    { label: "Others", value: "99" }
  ];
  

export const CHARGE_ITEM_STATUS_OPTIONS = [
    { "label": "Planned", "value": "planned" },
    { "label": "Billable", "value": "billable" },
    { "label": "Not Billable", "value": "not-billable" },
    { "label": "Aborted", "value": "aborted" },
    { "label": "Billed", "value": "billed" },
    { "label": "Entered in Error", "value": "entered-in-error" },
    { "label": "Unknown", "value": "unknown" }
]

export const CHARGE_ITEM_CODE_OPTIONS = [
    { label: "Consultation", value: "00" },
    { label: "Pharmacy", value: "01" },
    { label: "IPD", value: "02" },
    { label: "OPD", value: "03" },
    { label: "Pathology", value: "04" },
    { label: "Medicines", value: "05" },
    { label: "Nursing Charges", value: "06" },
    { label: "Handling Charges", value: "07" },
    { label: "Delivery Charges", value: "08" },
    { label: "Others", value: "99" }
  ];
  

export const INVOICE_PRICE_COMPONENT_CODE_OPTIONS = [
    { "label": "MRP", "value": "00" },
    { "label": "Rate", "value": "01" },
    { "label": "Discount", "value": "02" },
    { "label": "CGST", "value": "03" },
    { "label": "SGST", "value": "04" }
];

export const INVOICE_PRICE_COMPONENT_TYPE_OPTIONS = [
    { "label": "Base Price", "value": "base" },
    { "label": "Surcharge", "value": "surcharge" },
    { "label": "Deduction", "value": "deduction" },
    { "label": "Discount", "value": "discount" },
    { "label": "Tax", "value": "tax" },
    { "label": "Informational", "value": "informational" }
];

  
export const AMOUNT_ISC_CODES = [
    { "label": "Indian rupee", "value": "INR" },
    { "label": "United Arab Emirates dirham", "value": "AED" },
    { "label": "Afghan afghani", "value": "AFN" },
    { "label": "Albanian lek", "value": "ALL" },
    { "label": "Armenian dram", "value": "AMD" },
    { "label": "Netherlands Antillean guilder", "value": "ANG" },
    { "label": "Angolan kwanza", "value": "AOA" },
    { "label": "Argentine peso", "value": "ARS" },
    { "label": "Australian dollar", "value": "AUD" },
    { "label": "Aruban florin", "value": "AWG" },
    { "label": "Azerbaijani manat", "value": "AZN" },
    { "label": "Bosnia and Herzegovina convertible mark", "value": "BAM" },
    { "label": "Barbados dollar", "value": "BBD" },
    { "label": "Bangladeshi taka", "value": "BDT" },
    { "label": "Bulgarian lev", "value": "BGN" },
    { "label": "Bahraini dinar", "value": "BHD" },
    { "label": "Burundian franc", "value": "BIF" },
    { "label": "Bermudian dollar", "value": "BMD" },
    { "label": "Brunei dollar", "value": "BND" },
    { "label": "Boliviano", "value": "BOB" },
    { "label": "Bolivian Mvdol (funds code)", "value": "BOV" },
    { "label": "Brazilian real", "value": "BRL" },
    { "label": "Bahamian dollar", "value": "BSD" },
    { "label": "Bhutanese ngultrum", "value": "BTN" },
    { "label": "Botswana pula", "value": "BWP" },
    { "label": "Belarusian ruble", "value": "BYN" },
    { "label": "Belize dollar", "value": "BZD" },
    { "label": "Canadian dollar", "value": "CAD" },
    { "label": "Congolese franc", "value": "CDF" },
    { "label": "Swiss franc", "value": "CHF" },
    { "label": "Chilean peso", "value": "CLP" },
    { "label": "Chinese yuan (Renminbi)", "value": "CNY" },
    { "label": "Colombian peso", "value": "COP" },
    { "label": "Costa Rican colon", "value": "CRC" },
    { "label": "Cuban convertible peso", "value": "CUC" },
    { "label": "Cuban peso", "value": "CUP" },
    { "label": "Czech koruna", "value": "CZK" },
    { "label": "Danish krone", "value": "DKK" },
    { "label": "Dominican peso", "value": "DOP" },
    { "label": "Algerian dinar", "value": "DZD" },
    { "label": "Egyptian pound", "value": "EGP" },
    { "label": "Ethiopian birr", "value": "ETB" },
    { "label": "Euro", "value": "EUR" },
    { "label": "Fiji dollar", "value": "FJD" },
    { "label": "British pound sterling", "value": "GBP" },
    { "label": "Ghanaian cedi", "value": "GHS" },
    { "label": "Hong Kong dollar", "value": "HKD" },
    { "label": "Hungarian forint", "value": "HUF" },
    { "label": "Indonesian rupiah", "value": "IDR" },
    { "label": "Iranian rial", "value": "IRR" },
    { "label": "Iraqi dinar", "value": "IQD" },
    { "label": "Israeli new shekel", "value": "ILS" },
    { "label": "Japanese yen", "value": "JPY" },
    { "label": "Kenyan shilling", "value": "KES" },
    { "label": "Kuwaiti dinar", "value": "KWD" },
    { "label": "Lao kip", "value": "LAK" },
    { "label": "Lebanese pound", "value": "LBP" },
    { "label": "Sri Lankan rupee", "value": "LKR" },
    { "label": "Malaysian ringgit", "value": "MYR" },
    { "label": "Mexican peso", "value": "MXN" },
    { "label": "Nepalese rupee", "value": "NPR" },
    { "label": "New Zealand dollar", "value": "NZD" },
    { "label": "Omani rial", "value": "OMR" },
    { "label": "Pakistani rupee", "value": "PKR" },
    { "label": "Philippine peso", "value": "PHP" },
    { "label": "Polish złoty", "value": "PLN" },
    { "label": "Qatari riyal", "value": "QAR" },
    { "label": "Russian ruble", "value": "RUB" },
    { "label": "Saudi riyal", "value": "SAR" },
    { "label": "Singapore dollar", "value": "SGD" },
    { "label": "South African rand", "value": "ZAR" },
    { "label": "South Korean won", "value": "KRW" },
    { "label": "Swedish krona", "value": "SEK" },
    { "label": "Swiss franc", "value": "CHF" },
    { "label": "Thai baht", "value": "THB" },
    { "label": "Turkish lira", "value": "TRY" },
    { "label": "United States dollar", "value": "USD" },
    { "label": "Vietnamese đồng", "value": "VND" },
    { "label": "Zambian kwacha", "value": "ZMW" },
    { "label": "Zimbabwean dollar", "value": "ZWL" }
]
  