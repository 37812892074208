import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { FORM_FIELDS_FOR_INVOICE, INVOICE_STATUS, INVOIE_TYPE_OPTIONS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import Icons from "../../../../../components/icons/Icons";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import Label from "../../../../../components/common-components/label/Label";
import LineItem from "./LineItem";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
const fields = [
    { fieldName: FORM_FIELDS_FOR_INVOICE.STATUS, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE, isRequired: true },
    { fieldName: FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_INVOICE.DATE, isRequired: true },
]
const validationSchema = getValidationSchema(fields);

const Invoice = ({onConfirm, close}) => {
    const [itemsList, setItemsList] = useState([]);
    const [isItemsPopupOpen, setIsItemsPopupOpen] = useState(false);
    const [itemsError, setItemsError] = useState("");
    const formikRef = useRef(null);

    useEffect(() => {
        if (itemsList.length > 0) setItemsError("");

        if (formikRef.current) {
            const totals = calculateTotals(itemsList.map((item) => item.data));
            formikRef.current.setFieldValue(FORM_FIELDS_FOR_INVOICE.TOTAL_NET, `${totals.totalNet.value || 0} ${totals.totalNet.currency || "INR" }`);
            formikRef.current.setFieldValue(FORM_FIELDS_FOR_INVOICE.TOTAL_GROSS, `${totals.totalGross.value || 0} ${totals.totalGross.currency || "INR"}`);
        }
    }, [itemsList]);

    function calculateTotals(lineItems) {
        let totalGross = 0;
        let totalNet = 0;
        let currency = lineItems[0]?.priceComponent[0]?.amount.currency;
    
        lineItems.forEach(item => {
            let baseAmount = 0;
            let surchargeAmount = 0;
            let taxAmount = 0;
            let discountAmount = 0;
            let deductionAmount = 0;
    
            item.priceComponent.forEach(component => {
                const amount = component.amount.value;
    
                switch (component.type) {
                    case "base":
                        baseAmount += amount;
                        break;
                    case "surcharge":
                        surchargeAmount += amount;
                        break;
                    case "tax":
                        taxAmount += amount;
                        break;
                    case "discount":
                        discountAmount += amount;
                        break;
                    case "deduction":
                        deductionAmount += amount;
                        break;
                    case "informational":
                        // Ignored in calculation
                        break;
                    default:
                        console.log("Other price component")
                        break;
                }
            });
    
            let itemGross = baseAmount + surchargeAmount + taxAmount;
            let itemNet = itemGross - (discountAmount + deductionAmount);
    
            totalGross += itemGross;
            totalNet += itemNet;
        });
    
        return {
            totalGross: { value: totalGross, currency: currency },
            totalNet: { value: totalNet, currency: currency }
        };
    }
    const totalAmount = calculateTotals(itemsList.map((item) => item.data))
    return(
        <>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    [FORM_FIELDS_FOR_INVOICE.STATUS]:"",
                    [FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE]:"",
                    [FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE]:"",
                    [FORM_FIELDS_FOR_INVOICE.DATE]:"",
                    [FORM_FIELDS_FOR_INVOICE.TOTAL_NET]: "",
                    [FORM_FIELDS_FOR_INVOICE.TOTAL_GROSS]: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if(itemsList.length === 0) {
                        setItemsError("Atleast one line item is required");
                        return;
                    }
                    const lineItems = itemsList.map((item) => item.data);
                    const totals = calculateTotals(lineItems);
                    const data = {
                        status: values[FORM_FIELDS_FOR_INVOICE.STATUS].value,
                        type:{
                            code: values[FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE].value,
                            display: values[FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE].label,
                        },
                        date: values[FORM_FIELDS_FOR_INVOICE.DATE],
                        lineItem: lineItems,
                        totalGross: totals.totalGross,
                        totalNet: totals.totalNet,
                    }
                    onConfirm((prev) => [...prev, { type: `Invoice ${prev.length + 1}`, data }]);
                    close();
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                    <div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl min-h-[35%] max-h-[90%] overflow-y-auto">
                        <div className="flex justify-between items-center border-b pb-3">
                            <Heading type={HEADING.H2} className="text-lg font-semibold">Invoice</Heading>
                            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                                <Icons iconName={"closeIcon"}/>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className=" grid grid-cols-2 gap-2">
                                <SelectDropdown
                                    label="Status"
                                    name={FORM_FIELDS_FOR_INVOICE.STATUS}
                                    isRequired={true}
                                    placeholder="Select"
                                    customClasses="w-full"
                                    value={values[FORM_FIELDS_FOR_INVOICE.STATUS]}
                                    options={INVOICE_STATUS}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue(FORM_FIELDS_FOR_INVOICE.STATUS, selectedOption)
                                    }
                                />
                                <Input
                                    label="Identifier Type"
                                    placeholder="Plain text representation of the concept"
                                    name={FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE}
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE, e.target.value)}
                                />
                                <SelectDropdown
                                    label="Invoice Type"
                                    name={FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE}
                                    isRequired={true}
                                    placeholder="Select"
                                    value={values[FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE]}
                                    options={INVOIE_TYPE_OPTIONS}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue(FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE, selectedOption)
                                    }
                                />
                                <Input
                                    label="Invoice date / posting date"
                                    name={FORM_FIELDS_FOR_INVOICE.DATE}
                                    type="datetime-local"
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.DATE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_INVOICE.DATE, e.target.value)}
                                />
                                <div className="col-span-2">
                                    <ItemManager
                                        key={`InvoiceLineItems`}
                                        title={<>Line items fo this Invoice <span className="text-red-500">*</span></>} 
                                        items={itemsList}
                                        setItems={setItemsList}
                                        setIsPopupOpen={setIsItemsPopupOpen}
                                        customHeight="52"
                                    />
                                    {itemsError && (
                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                            {itemsError}
                                        </Label>
                                    )}
                                </div>
                                <Input 
                                    label="Total Net" 
                                    name={FORM_FIELDS_FOR_INVOICE.TOTAL_NET} 
                                    value={values[FORM_FIELDS_FOR_INVOICE.TOTAL_NET]} 
                                    isRequired={true}
                                    disabled 
                                />
                                <Input 
                                    label="Total Gross" 
                                    name={FORM_FIELDS_FOR_INVOICE.TOTAL_GROSS} 
                                    value={values[FORM_FIELDS_FOR_INVOICE.TOTAL_GROSS]} 
                                    isRequired={true}
                                    disabled 
                                />

                            </div>
                            <div className="flex justify-end">
                                <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
        {isItemsPopupOpen &&
            <LineItem
                onConfirm={setItemsList}
                close={() => setIsItemsPopupOpen(false)}
            />
        }
    </>
    )
}
export default Invoice;