import Heading from "../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../components/common-components/zoom/constants";
import Modal from "./Modal";
import { componentKey } from "../PatientDashboardSlice";
import { useSelector } from "react-redux";
import SuccessSvg from "../../../../components/icons/vectors/SuccessSvg";
import Label from "../../../../components/common-components/label/Label";
import SandClock from "./AddRecords/SandClock";

const LoadingPopup = ({ isOpen, handleCancle, handleClose }) => {
    const { bundlePushResponse } = useSelector((state) => state[componentKey]);
    const modalProps = bundlePushResponse?.success
    ? {
          onConfirm: handleCancle,
          submitText: "Okay",
      }
    : {onClose: handleClose};
    return (
        <Modal
            title=""
            isOpen={isOpen}
            {...modalProps}
        >
            <div className="flex flex-col gap-4 justify-center items-center p-2">
                {bundlePushResponse?.success ? <SuccessSvg/> : <SandClock/>
                }
                <Heading type={HEADING.H2}>{bundlePushResponse?.message ? bundlePushResponse.message : "ABDM Linking is in progress. Please wait ...."}</Heading>
                {!bundlePushResponse?.success && <Label>For better accuracy in linking, pull the records in the ABHA app.</Label>}
            </div>
        </Modal> 
    );
};

export default LoadingPopup;


