import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { IMMUNIZATION_RECOMMENDATION_FIELDS} from "../Constants";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import ItemManager from "../ItemManager";
import ImmunizationRecommendationRecommendation from "./ImmunizationRecommendation_Recommendation";
import Label from "../../../../../components/common-components/label/Label";
const fields = [
    { fieldName: IMMUNIZATION_RECOMMENDATION_FIELDS.DATE, isRequired: true },
];
const validationSchema = getValidationSchema(fields);

const ImmunizationRecommendation = ({ open, close, onConfirm }) => {
    const [recommendationList, setRecommendationList] = useState([]);
    const [isRecommendationPopupOpen, setIsRecommendationPopupOpen] = useState(false);
    const [recommendationError, setRecommendationError] = useState("")
    
    useEffect(() => {
        if(recommendationList.length > 0) setRecommendationError("");
    }, [recommendationList])
    return (
        <>
            <Formik
                initialValues={{
                    [IMMUNIZATION_RECOMMENDATION_FIELDS.DATE]:"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    if(recommendationList.length === 0) {
                        setRecommendationError("Atleast one recommendation is required");
                        return;
                    }
                    const data = {
                        date: values[IMMUNIZATION_RECOMMENDATION_FIELDS.DATE],
                        recommendation: recommendationList.map((item) => item.data),
                    };
                    onConfirm((prev) => [...prev, { type: `Recommendation ${prev.length + 1}`, data }]);
                    setSubmitting(false);
                    close();
                }}
                
                
            >
                {({ values, errors, setFieldValue, handleSubmit, handleBlur }) => (
                    
                    <ModalComponent
                        open={open}
                        title={"Add Immunization recommendation"}
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED}
                                onClickCb={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        }
                        close={close}
                        customClasses="w-[90%] max-w-[800px]"
                        customBodyClasses="overflow-visible"
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <Input
                                label="Date"
                                name={IMMUNIZATION_RECOMMENDATION_FIELDS.DATE}
                                value={values[IMMUNIZATION_RECOMMENDATION_FIELDS.DATE]}
                                type="datetime-local"
                                isRequired={true}
                                onChangeCb={(e) =>
                                    setFieldValue(IMMUNIZATION_RECOMMENDATION_FIELDS.DATE, e.target.value)
                                }
                                onBlurCb={handleBlur}
                            />
                            <div className="col-span-2">
                                <ItemManager
                                    title={<>Recommendations <span className="text-red-500">*</span></>} 
                                    items={recommendationList}
                                    setItems={setRecommendationList}
                                    setIsPopupOpen={setIsRecommendationPopupOpen}
                                    customHeight="52"
                                />
                                {recommendationError && <Label fontSize="sm" fontWeight="bold" color="red-500">{recommendationError}</Label>}
                            </div>
                            
                        </div>
                    </ModalComponent>
                )}
            </Formik>
            {isRecommendationPopupOpen && 
                <ImmunizationRecommendationRecommendation
                    open={isRecommendationPopupOpen}
                    close={() => setIsRecommendationPopupOpen(false)}
                    onConfirm={setRecommendationList}
                />
            }
        </>
    );
};

export default ImmunizationRecommendation;
