import store from '../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setPatientData, setSelectedBundle, setDecryptedData, setBundlesData, setBundlePushResponse, setBundlePushError } from './PatientDashboardSlice';
import PatientDataService from '../../../services/PatientDataServices';
import { addNotifications } from '../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../components/common-components/toaster/MetToaster';

export const {
    getPatientDetails, createDiagnosticBundle, createDischargeSummaryBundle, createHealthDocumentBundle, createOpConsultationBundle, createPrescriptionBundle, createWellnessBundle, createImmunizationBundle, createInvoiceBundle, pushBundle, retryDataPush, consentIdRequest, consentRequestStatus, getDecryptedData
} = {
    getPatientDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/PATIENTDETAILS',
            payload,
        };
    },
    createDiagnosticBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_DIAGNOSTIC_BUNDLE',
            payload,
        }
    },
    createDischargeSummaryBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_DISCHARGE_SUMMARY_BUNDLE',
            payload,
        }
    },
    createHealthDocumentBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_HEALTH_DOCUMENT_BUNDLE',
            payload,
        }
    },
    createOpConsultationBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_OP_CONSULTATION_BUNDLE',
            payload,
        }
    },
    createPrescriptionBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_PRESCRPTION_BUNDLE',
            payload,
        }
    },
    createWellnessBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_WELLNESS_BUNDLE',
            payload,
        }
    },
    createImmunizationBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/IMMUNIZATION_BUNDLE',
            payload,
        }
    },
    createInvoiceBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/INVOICE_BUNDLE',
            payload,
        }
    },
    pushBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/PUSH_BUNDLE',
            payload,
        }
    },
    retryDataPush: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/RETRY_DATA_PUSH',
            payload,
        }
    },
    consentIdRequest: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CONSENT_ID_REQUEST',
            payload,
        }
    },
    consentRequestStatus: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CONSENT_REQUEST_STATUS',
            payload,
        }
    },
    getDecryptedData: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/GET_DECRYPTED_DATA',
            payload,
        }
    }
};

function* getPatientDetailsAsync(action) {
    try {
        const { patientId = '', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDataService.getPatientDetails(
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setPatientData(data));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* createDiagnosticBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createDiagnosticBundle(payload);
        if(response.status === 201){
            yield put(setSelectedBundle(response.data));
            //yield put(addNotifications({ message: "Diagnostic bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }
    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* createPrescriptionBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createPrescriptionBundle(payload);
        if(response.status === 201){
            yield put(setSelectedBundle(response.data));
            //yield put(addNotifications({ message: "Prescription bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* createDischargeSummaryBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createDischargeSummaryBundle(payload);
        if(response.status === 201){
            yield put(setSelectedBundle(response.data));
            //yield put(addNotifications({ message: "Discharge summary bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* createHealthDocumentBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createHealthDocumentBundle(payload);
        if(response.status === 201){
            yield put(setSelectedBundle(response.data));
            //yield put(addNotifications({ message: "Health Document bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* createOpConsultationBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createOpConsultationBundle(payload);
        if(response.status === 201){
            yield put(setSelectedBundle(response.data));
            //yield put(addNotifications({ message: "Op consultation bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* createWellnessBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createWellnessBundle(payload);
        if(response.status === 201){
            yield put(setSelectedBundle(response.data));
            //yield put(addNotifications({ message: "Wellness bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}
function* createImmunizationBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createImmunizationBundle(payload);
        if(response.status === 201){
            yield put(setSelectedBundle(response.data));
            //yield put(addNotifications({ message: "Immunization bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}
function* createInvoiceBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createInvoiceBundle(payload);
        if(response.status === 201){
            yield put(setSelectedBundle(response.data));
            //yield put(addNotifications({ message: "Invoice bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* pushBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.pushBundle(payload);
        if(response.status === 201){
            yield put(setBundlePushResponse(response.data));
            yield put(addNotifications({ message: "Bundle linked successfully with ABHA" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
            yield put(setBundlePushError(error?.response?.data));
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* retryDataPushAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.retryDataPush(payload);
        if(response.status === 201){
            yield put(setBundlePushResponse(response.data));
            yield put(addNotifications({ message: "Bundle linked successfully with ABHA" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
            yield put(setBundlePushError(error?.response?.data));
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* consentIdRequestAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.consentIdRequest(payload);
        if(response.status === 201){
            yield put(addNotifications({ message: "Consent ID created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* consentRequestStatusAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.consentRequestStatus(payload);
        // if(response.status === 201){
        //     yield put(addNotifications({ message: "Consent status requested successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        // }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* getDecryptedDataAsync(action) {
    try{
        const {entries, payloadData} = action.payload;
        const responses = yield all(
            entries.map(item => call(PatientDataService.getDecryptedData, { ...payloadData, encryptedData: item.content }))
        );

        const decryptedData = responses.map(response => response.data);
        yield put(setBundlesData(decryptedData));
        yield put(addNotifications({ message: "Decrypted data fetched successfully", variant: TOASTER_VARIANT.SUCCESS }));

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getPatientDetails().type, getPatientDetailsAsync),
        takeLatest(createDiagnosticBundle().type, createDiagnosticBundleAsync),
        takeLatest(createDischargeSummaryBundle().type, createDischargeSummaryBundleAsync),
        takeLatest(createHealthDocumentBundle().type, createHealthDocumentBundleAsync),
        takeLatest(createOpConsultationBundle().type, createOpConsultationBundleAsync),
        takeLatest(createPrescriptionBundle().type, createPrescriptionBundleAsync),
        takeLatest(createWellnessBundle().type, createWellnessBundleAsync),
        takeLatest(createImmunizationBundle().type, createImmunizationBundleAsync),
        takeLatest(createInvoiceBundle().type, createInvoiceBundleAsync),
        takeLatest(pushBundle().type, pushBundleAsync),
        takeLatest(retryDataPush().type, retryDataPushAsync),
        takeLatest(consentIdRequest().type, consentIdRequestAsync),
        takeLatest(consentRequestStatus().type, consentRequestStatusAsync),
        takeLatest(getDecryptedData().type, getDecryptedDataAsync),
        
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);
