import React from "react";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";
import { FieldRow } from "./FHIRBundleViewer";

const ImmunizationRecommendation = ({ resource }) => {
  return (
    <div className="mb-4 border border-gray-200 p-6 rounded">
      <Heading type={HEADING.H2} className="text-xl font-semibold mb-4">
        {resource.resourceType}
      </Heading>
      {resource.recommendation && resource.recommendation.length > 0 && (
        resource.recommendation.map((recommendation, index) => (
          <div key={index} className="mb-4 p-4 border rounded">
            <Heading type={HEADING.H4} className="font-medium mb-2">
              {`Recommendation ${index + 1}`}
            </Heading>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              <FieldRow
                label="Target Disease"
                value={
                  recommendation.targetDisease?.coding?.[0]?.display ||
                  recommendation.targetDisease?.text
                }
              />
              <FieldRow
                label="Forecast Status"
                value={
                  recommendation.forecastStatus?.coding?.[0]?.display ||
                  recommendation.forecastStatus?.text
                }
              />
            </div>
            {recommendation.forecastReason && recommendation.forecastReason.length > 0 && (
              <div className="mt-4 p-4 border-l-2 rounded">
                <Heading type={HEADING.H4} className="font-medium mb-2">
                  Forecast Reasons
                </Heading>
                <div className="flex gap-4 flex-wrap">
                  {recommendation.forecastReason.map((reason, idx) => (
                    <div key={idx}>{reason.text}</div>
                  ))}
                </div>
              </div>
            )}
            {recommendation.contraindicatedVaccineCode && recommendation.contraindicatedVaccineCode.length > 0 && (
              <div className="mt-4 p-4 border-l-2 rounded">
                <Heading type={HEADING.H4} className="font-medium mb-2">
                  Contraindicated Vaccines
                </Heading>
                <div className="flex gap-4 flex-wrap">
                  {recommendation.contraindicatedVaccineCode.map((contra, idx) => (
                    <div key={idx}>{contra.text}</div>
                  ))}
                </div>
              </div>
            )}
            {recommendation.vaccineCode && recommendation.vaccineCode.length > 0 && (
              <div className="mt-4 p-4 border-l-2 rounded">
                <Heading type={HEADING.H4} className="font-medium mb-2">
                  Recommended Vaccines
                </Heading>
                <div className="flex gap-4 flex-wrap">
                  {recommendation.vaccineCode.map((vaccine, idx) => (
                    <div key={idx}>
                      {vaccine.coding?.[0]?.display || vaccine.text}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default ImmunizationRecommendation;
