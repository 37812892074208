import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { FORM_FIELDS_FOR_HEALTH_DOC, RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey, setBundlePushError, setBundlePushResponse, setSelectedBundle } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { createHealthDocumentBundle, pushBundle, retryDataPush } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";

import HealthRecordHelper from "../utils/utils";
import DocumentReference from "./DocumentReference";
import LoadingPopup from "../LoadingPopup";
import Encounter from "./Encounter";

const HealthDocumentRecord = ({ handleCancle, payloadHelper, title, setTitleError, setTitleSubmitted }) => {
  const { patientData, selectedBundle, bundlepushError } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [encounterItem, setEncounterItem] = useState([]);
  const [isEncounterPopupOpen, setIsEncounterPopupOpen] = useState(false);
  const [encounterError, setEncounterError] = useState("");

  const [docRefList, setDocRefList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reportsError, setReportsError] = useState("");
  const [loadingpopup, setLoadingPopup] = useState(false);
  const [isLinkingFailed, setIsLinkingFailed] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values[FORM_FIELDS_FOR_HEALTH_DOC.STATUS] || values[FORM_FIELDS_FOR_HEALTH_DOC.STATUS].value === "Select Status") {
      errors[FORM_FIELDS_FOR_HEALTH_DOC.STATUS] = "Please select status.";
    }
    if (!values[FORM_FIELDS_FOR_HEALTH_DOC.DATE]) {
      errors[FORM_FIELDS_FOR_HEALTH_DOC.DATE] = "Please provide a date and time.";
    }
    if (!values[FORM_FIELDS_FOR_HEALTH_DOC.TITLE]) {
      errors[FORM_FIELDS_FOR_HEALTH_DOC.TITLE] = "Please provide a title.";
    }
    if(!title){
      setTitleError("Please provide the title")
    }
    if (encounterItem.length === 0) {
      setEncounterError("Encounter entry is required.");
    }
    if(docRefList.length === 0) {
        setReportsError("Atleast 1 Document reference is required.");
    }
    return errors;
  };

  useEffect(() => {
    if(docRefList.length > 0) setReportsError("");
    if(encounterItem.length > 0) setEncounterError("");
  },[docRefList, encounterItem])

  useEffect(() => {
      if(selectedBundle){
        const payload = {
            ...payloadHelper,
            patientId: patientData?.uuid,
            bundleData: selectedBundle,
        }
        if(isLinkingFailed) {
          payload.previous = true;
          dispatch(retryDataPush(payload))
        } else {
          dispatch(pushBundle(payload));
        }
        setLoadingPopup(true);
      }
  },[dispatch, selectedBundle])

  useEffect(() => {
    if(bundlepushError?.statusCode){
      setLoadingPopup(false);
      setIsLinkingFailed(true)
      dispatch(setBundlePushError({}))
      dispatch(setSelectedBundle(null))
    }
  }, [bundlepushError, dispatch])

  useEffect(() => {
      return () => {
          dispatch(setSelectedBundle(null))
          dispatch(setBundlePushResponse({}))
          dispatch(setBundlePushError({}))
      }
  }, [dispatch])

  const structurePayloadWithAllData = (values) => {
      const payload = {
        status: values[FORM_FIELDS_FOR_HEALTH_DOC.STATUS].value,
        date: values[FORM_FIELDS_FOR_HEALTH_DOC.DATE],
        title: values[FORM_FIELDS_FOR_HEALTH_DOC.TITLE],
        subject: HealthRecordHelper.getStructurePatientData(patientData),
        encounter: encounterItem[0]?.data,
        entry: docRefList?.map((docRef) => docRef.data),
      };
      return payload;
  };

  return (
      <>
          <Formik
              initialValues={{
                    [FORM_FIELDS_FOR_HEALTH_DOC.STATUS]: { label: "Select Status", value: "Select Status" },
                    [FORM_FIELDS_FOR_HEALTH_DOC.DATE]:"",
                    [FORM_FIELDS_FOR_HEALTH_DOC.TITLE]:"",
                    [FORM_FIELDS_FOR_HEALTH_DOC.DOC_REF]:"",
              }}
              validate={validate}
              onSubmit={(values) => {
                dispatch(setSelectedBundle(null));
                if(docRefList.length === 0 || encounterItem.length === 0) {
                  return;
                }
                const payload = structurePayloadWithAllData(values);
                dispatch(createHealthDocumentBundle(payload))
              }}
          >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                  <form 
                    onSubmit={(e) => {
                      setTitleSubmitted(true)
                      setSubmitAttempted(true)
                      handleSubmit(e);
                    }} 
                    className=" h-full w-full flex flex-col justify-between"
                  >
                      <div className="flex flex-col gap-2">
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                            <SelectDropdown
                                label="Composition Status"
                                name={FORM_FIELDS_FOR_HEALTH_DOC.STATUS}
                                isRequired={true}
                                customClasses="w-full"
                                value={values[FORM_FIELDS_FOR_HEALTH_DOC.STATUS]}
                                options={[{ label: "Select Status", value: "Select Status" }, ...RECORD_STATUS]}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue(FORM_FIELDS_FOR_HEALTH_DOC.STATUS, selectedOption)
                                }
                            />
                            <Input
                              label="Health Document Editing Time"
                              name={FORM_FIELDS_FOR_HEALTH_DOC.DATE}
                              type="datetime-local"
                              isRequired={true}
                              value={values[FORM_FIELDS_FOR_HEALTH_DOC.DATE]}
                              onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_HEALTH_DOC.DATE, e.target.value)}
                            />
                            <Input
                              label="Title"
                              placeholder="Human Readable name/title"
                              type="text"
                              name={FORM_FIELDS_FOR_HEALTH_DOC.TITLE}
                              isRequired={true}
                              value={values[FORM_FIELDS_FOR_HEALTH_DOC.TITLE]}
                              onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_HEALTH_DOC.TITLE, e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col gap-[8px]">
                          <div className="flex flex-col">
                            <ItemManager
                                key={`op_encounter_entry_healthDoc`}
                                title="Encounter entry"
                                isRequired={true}
                                items={encounterItem}
                                setItems={setEncounterItem}
                                setIsPopupOpen={(value) => {
                                    if(encounterItem.length < 1){
                                        setIsEncounterPopupOpen(value)
                                    }else setEncounterError("Only one encounter is allowed")
                                    
                                }}
                                customHeight="52"
                            />
                            {submitAttempted && encounterError && (
                                <Label fontSize="md" color="red-500">
                                    {encounterError}
                                </Label>
                            )}
                          </div>
                          <div className="flex flex-col">
                            <ItemManager
                                key={`documentRef_healthDoc`}
                                title="Document reference entry" 
                                items={docRefList}
                                isRequired = {true}
                                setItems={setDocRefList}
                                setIsPopupOpen={setIsPopupOpen}
                                customHeight="96"
                            />
                            {reportsError && submitAttempted && <Label fontSize="md" color="red-500">{reportsError}</Label>}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end gap-2">
                          <Button 
                              onClickCb={handleCancle} 
                              variant={BUTTON_VARIANTS.OUTLINED}
                          >Cancel</Button>
                          <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                            {isLinkingFailed ? "Try Again" : "Link to ABDM"}
                          </Button>
                        </div>
                    </form>
                )}
            </Formik>
            {isEncounterPopupOpen && 
              <Encounter
                  open={isEncounterPopupOpen}
                  onConfirm={setEncounterItem}
                  close={() => setIsEncounterPopupOpen(false)}
              />
            }
            {isPopupOpen && 
                <DocumentReference 
                    open={isPopupOpen}
                    onConfirm={setDocRefList} 
                    close={() => setIsPopupOpen(false)}
                />
            }
            <LoadingPopup
              isOpen={loadingpopup} 
              handleCancle = {() => {
                setLoadingPopup(false)
                handleCancle()
              }}
              handleClose = {() => setLoadingPopup(false)}
            />
      </>
  );
};

export default HealthDocumentRecord;
