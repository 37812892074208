import React, { useState } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { ABHA_HEALTH_RECORD_FIELDS, FORM_OBSERVATION_FIELDS, OBSERVATION_STATUS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import ItemManager from "../ItemManager";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import { v4 as uuidv4 } from 'uuid';
import Icons from "../../../../../components/icons/Icons";
import { getValidationSchema } from "../../../../../libs/formsUtils";
const fields = [
    { fieldName: FORM_OBSERVATION_FIELDS.STATUS, isRequired: true, isDropdown: true },
    { fieldName: FORM_OBSERVATION_FIELDS.CODE, isRequired: true },
    { fieldName: FORM_OBSERVATION_FIELDS.VALUE, isRequired: true },
    { fieldName: FORM_OBSERVATION_FIELDS.UNITS, isRequired: true },
];

const validationSchema = getValidationSchema(fields);

const Observation = ({ onConfirm, close, setObservationError, isNested = false }) => {
    const [observationList, setObservationList] = useState([]);
    const [isObservationPopupOpen, setIsObservationPopupOpen] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    [FORM_OBSERVATION_FIELDS.STATUS]:"",
                    [FORM_OBSERVATION_FIELDS.CODE]:"",
                    [FORM_OBSERVATION_FIELDS.VALUE]:"",
                    [FORM_OBSERVATION_FIELDS.UNITS]:"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const formattedData = {
                        resourceType: ABHA_HEALTH_RECORD_FIELDS.OBSERVATION,
                        status: values[FORM_OBSERVATION_FIELDS.STATUS].value,
                        code: values[FORM_OBSERVATION_FIELDS.CODE],
                        valueQuantity: {
                            value: Number(values[FORM_OBSERVATION_FIELDS.VALUE]),
                            unit: values[FORM_OBSERVATION_FIELDS.UNITS]
                        },
                        ...(observationList.length > 0 ? {hasMember: observationList.map((item) => item.data)} : {})
                    }

                    onConfirm((prev) => [
                        ...prev, { type: `Observation ${prev.length + 1}`, data: formattedData }
                    ]);
                    if (setObservationError) {
                        setObservationError("");
                    }
                    close();
                }}
            >
                {({ values, handleSubmit, setFieldValue, handleBlur }) => (
                    <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                        <div className="bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl max-h-screen ">
                            <div className="flex justify-between items-center border-b pb-3">
                                <h2 className="text-lg font-semibold">Add Observation</h2>
                                <div className="flex justify-end items-center pb-3 hover:cursor-pointer" onClick={close}>
                                    <Icons iconName={"closeIcon"}/>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                                <div className="grid grid-cols-2 gap-2 mt-2">
                                    <SelectDropdown
                                        label="Select Status"
                                        isRequired={true}
                                        name={FORM_OBSERVATION_FIELDS.STATUS}
                                        placeholder="Select"
                                        customClasses="w-full"
                                        value={values[FORM_OBSERVATION_FIELDS.STATUS]}
                                        options={OBSERVATION_STATUS}
                                        onChangeCb={(selectedOption) =>
                                            setFieldValue(FORM_OBSERVATION_FIELDS.STATUS, selectedOption)
                                        }
                                    />

                                    <Input
                                        label="Code"
                                        placeholder="Enter Type of observation (code / type) Ex: Blood Pressure"
                                        name= {FORM_OBSERVATION_FIELDS.CODE}
                                        type="text"
                                        isRequired={true}
                                        value={values[FORM_OBSERVATION_FIELDS.CODE]}
                                        onChangeCb={(e) => setFieldValue(FORM_OBSERVATION_FIELDS.CODE, e.target.value)}
                                        onBlurCb={handleBlur}
                                    />


                                    <Input
                                        label="Value"
                                        isRequired={true}
                                        placeholder="Enter Actual result"
                                        name={FORM_OBSERVATION_FIELDS.VALUE}
                                        value={values[FORM_OBSERVATION_FIELDS.VALUE]}
                                        type="number"
                                        onChangeCb={(e) => setFieldValue(FORM_OBSERVATION_FIELDS.VALUE, e.target.value)}
                                    />

                                    <Input
                                        label="Value Units"
                                        isRequired={true}
                                        placeholder={`Please provide units for value. Ex: mm Hg`}
                                        name={FORM_OBSERVATION_FIELDS.UNITS}
                                        value={values[FORM_OBSERVATION_FIELDS.UNITS]}
                                        onChangeCb={(e) => setFieldValue(FORM_OBSERVATION_FIELDS.UNITS, e.target.value)}
                                    />
                                    {isNested &&
                                        <ItemManager
                                            key={"Related resource that belongs to the Observation group"}
                                            title={"Related resource that belongs to the Observation group"}
                                            items={observationList}
                                            setItems={setObservationList}
                                            setIsPopupOpen={setIsObservationPopupOpen}
                                            customHeight="44"
                                            customClass={"col-span-2"}
                                        />
                                    }
                                </div>
                                <div className="flex justify-end">
                                    <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Formik>
            {isObservationPopupOpen && 
                <Observation 
                    key={`nestedObservation_${uuidv4()}`} 
                    onConfirm={setObservationList} 
                    close={() => setIsObservationPopupOpen(false)}
                    items={observationList}
                />
            }
        </>
    );
};

export default Observation;
