import React, { useEffect, useState } from "react";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { RECORD_STATUS, IMMUNIZATIO_RECORD } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey, setBundlePushError, setBundlePushResponse, setSelectedBundle } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Label from "../../../../../components/common-components/label/Label";
import DocumentReference from "./DocumentReference";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import { createImmunizationBundle, pushBundle, retryDataPush } from "../../PatientDashboardSaga";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import Immunization from "./Immunization";
import ImmunizationRecommendation from "./ImmunizationRecommendation";
import HealthRecordHelper from "../utils/utils";
import LoadingPopup from "../LoadingPopup";
import Encounter from "./Encounter";

// const fields = [
// 	{ fieldName: IMMUNIZATIO_RECORD.STATUS, isRequired: true, isDropdown: true },
// ];

// const validationSchema = getValidationSchema(fields);

const ImmunizationRecord = ({ handleCancle, payloadHelper, title, setTitleError, setTitleSubmitted }) => {
    const { patientData, selectedBundle, bundlepushError } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [documentReferenceList, setDocumentReferenceList] = useState([]);
    const [immunizationList, setImmunizationList] = useState([])
    const [immunizationRecommendationList, setImmunizationRecommendationList] = useState([]);
    const [encounterItem, setEncounterItem] = useState([]);
    const [isEncounterPopupOpen, setIsEncounterPopupOpen] = useState(false);
    const [encounterError, setEncounterError] = useState("");

    const [isDocumentReferencePopupOpen, setIsDocumentReferencePopupOpen] = useState(false);
    const [isImmunizationPopupOpen, setIsImmunizationPopupOpen] = useState(false);
    const [isImmunizationRecommendationOpen, setIsImmunizationRecommendationOpen] = useState(false);
    const [loadingpopup, setLoadingPopup] = useState(false);
    const [isLinkingFailed, setIsLinkingFailed] = useState(false);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    

    const [sectionError, setSectionError] = useState("");
    const [recommendationError, setRecommendationError] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if(selectedBundle){
            const payload = {
                ...payloadHelper,
                patientId: patientData?.uuid,
                bundleData: selectedBundle,
            }
            if(isLinkingFailed) {
                payload.previous = true;
                dispatch(retryDataPush(payload))
            } else {
                dispatch(pushBundle(payload));
            }
            setLoadingPopup(true);
        }
    },[dispatch, selectedBundle])

    useEffect(() => {
        if(bundlepushError?.statusCode){
            setLoadingPopup(false);
            setIsLinkingFailed(true)
            dispatch(setBundlePushError({}))
            dispatch(setSelectedBundle(null))
        }
    }, [bundlepushError, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setSelectedBundle(null))
            dispatch(setBundlePushResponse({}))
            dispatch(setBundlePushError({}))
        }
    }, [dispatch])

    useEffect(() => {
        if(documentReferenceList.length > 0 || immunizationList.length > 0 || immunizationRecommendationList.length > 0){
            setSectionError("");
            setRecommendationError("");
        }
        if(encounterItem.length > 0) setEncounterError("");
    },[documentReferenceList, immunizationList, immunizationRecommendationList, encounterItem]) 

    const structurePayloadWithAllData = (values) => {
        const payload = {
            status: values[IMMUNIZATIO_RECORD.STATUS].value,
            subject: HealthRecordHelper.getStructurePatientData(patientData),
            encounter: encounterItem[0]?.data,
            section: [
                immunizationList.length > 0 && {
                    immunizationEntry: immunizationList.map((item) => item.data),
                },
                immunizationRecommendationList.length > 0 && {
                    immunizationRecommendation: immunizationRecommendationList.map((item) => item.data)[0],
                },
                documentReferenceList.length > 0 && {
                    documentReferenceEntry: documentReferenceList.map((item) => item.data),
                },
            ].filter(Boolean),
        };
        return payload;
    };

    const validate = (values) => {
        const errors = {};
        if (!values[IMMUNIZATIO_RECORD.STATUS]?.value) {
            errors[IMMUNIZATIO_RECORD.STATUS] = "Please select a composition status.";
        }
        if(!title){
            setTitleError("Please provide the title")
        }
        if (encounterItem.length === 0) {
            setEncounterError("Encounter entry is required.");
        }
        const payload = structurePayloadWithAllData(values);
        if(payload.section.length <= 0) {
            setSectionError("Atleast 1 entry is required")
        }
        return errors;
    };

    return (
        <>
        <Formik
            initialValues={{
                [IMMUNIZATIO_RECORD.STATUS]: "",
            }}
            validate={validate}
            onSubmit={(values) => {
                dispatch(setSelectedBundle(null));
                const payload = structurePayloadWithAllData(values);
                if(payload.section.length <= 0 || encounterItem.length === 0) {
                    return;
                }
                dispatch(createImmunizationBundle(payload));
            }}
        >
            {({ values, errors, setFieldValue, handleSubmit }) => (
                <>
                    <form
                        onSubmit={(e) => {
                            setTitleSubmitted(true)
                            setSubmitAttempted(true)
                            handleSubmit(e);
                        }} 
                        className="w-full flex-1 flex flex-col gap-4 justify-between"
                    >
                        <div className="h-[42vh] w-full flex flex-col gap-[8px]  justify-between overflow-y-auto pr-2 met-scrollbar">
                            <div className="h-full flex flex-col gap-2 ">
                                <SelectDropdown
                                    label="Composition Status"
                                    name={IMMUNIZATIO_RECORD.STATUS}
                                    isRequired={true}
                                    customClasses="w-1/2"
                                    placeholder="Select"
                                    value={values[IMMUNIZATIO_RECORD.STATUS]}
                                    options={RECORD_STATUS}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue(IMMUNIZATIO_RECORD.STATUS, selectedOption)
                                    }
                                />
                                <div className="flex flex-col">
                                    <ItemManager
                                        key={`op_encounter_entry_immunization`}
                                        title="Encounter entry"
                                        isRequired = {true}
                                        items={encounterItem}
                                        setItems={setEncounterItem}
                                        setIsPopupOpen={(value) => {
                                            if(encounterItem.length < 1){
                                                setIsEncounterPopupOpen(value)
                                            }else setEncounterError("Only one encounter is allowed")
                                            
                                        }}
                                        customHeight="52"
                                    />
                                    {encounterError && submitAttempted && (
                                        <Label fontSize="md" color="red-500">
                                            {encounterError}
                                        </Label>
                                    )}
                                </div>
                                <div>
                                    <div className="px-4 py-2 border-2 border-gray-200 rounded-lg flex flex-col gap-2">
                                        <Heading type={HEADING.H2}> Section <span className="text-red-500">* {sectionError && submitAttempted && (
                                            <Label fontSize="sm " color="red-500">
                                                    ({sectionError})
                                            </Label>
                                        )}</span></Heading>
                                        <div className="max-h-[35vh] overflow-y-auto">
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                                <ItemManager
                                                    key={`Immunization`}
                                                    title={ "Immunization entry"}
                                                    items={immunizationList}
                                                    setItems={setImmunizationList}
                                                    setIsPopupOpen={setIsImmunizationPopupOpen}
                                                    customHeight="52"
                                                />
                                                <div>
                                                    <ItemManager
                                                        key={`ImmunizationRecommendation`}
                                                        title={ "Immunization Recommendation entry"}
                                                        items={immunizationRecommendationList}
                                                        setItems={setImmunizationRecommendationList}
                                                        setIsPopupOpen={(value) => {
                                                            if(immunizationRecommendationList.length < 1){
                                                                setIsImmunizationRecommendationOpen(value)
                                                            }else setRecommendationError("Only one immunization recommendation is allowed")
                                                            
                                                        }}
                                                        customHeight="52"
                                                    />
                                                    {recommendationError && (
                                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                                            {recommendationError}
                                                        </Label>
                                                    )}
                                                </div>
                                                
                                                <ItemManager
                                                    key={`ImmunizationDocumentReference`}
                                                    title={ "Document Reference	 entry"}
                                                    items={documentReferenceList}
                                                    setItems={setDocumentReferenceList}
                                                    setIsPopupOpen={setIsDocumentReferencePopupOpen}
                                                    customHeight="52"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* {sectionError && (
                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                            {sectionError}
                                        </Label>
                                    )} */}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button 
                                onClickCb={handleCancle} 
                                variant={BUTTON_VARIANTS.OUTLINED}
                            >Cancel</Button>
                            <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                {isLinkingFailed ? "Try Again" : "Link to ABDM"}
                            </Button>
                        </div>
                    </form>
                </>
            
            )}
        </Formik>
        {isEncounterPopupOpen && 
            <Encounter
                open={isEncounterPopupOpen}
                onConfirm={setEncounterItem}
                close={() => setIsEncounterPopupOpen(false)}
            />
        }
        {isImmunizationPopupOpen && 
            <Immunization 
                onConfirm = {setImmunizationList} 
                close = {() => setIsImmunizationPopupOpen(false)}
            />
        }
        {isImmunizationRecommendationOpen && 
            <ImmunizationRecommendation 
                open={isImmunizationRecommendationOpen}
                onConfirm = {setImmunizationRecommendationList} 
                close = {() => setIsImmunizationRecommendationOpen(false)}
            />
        }
        {isDocumentReferencePopupOpen &&
            <DocumentReference
                open={isDocumentReferencePopupOpen}
                onConfirm={setDocumentReferenceList}
                close={() => setIsDocumentReferencePopupOpen(false)}
            />
        }
        <LoadingPopup
            isOpen={loadingpopup} 
            handleCancle = {() => {
                setLoadingPopup(false)
                handleCancle()
            }}
            handleClose = {() => setLoadingPopup(false)}
        />
        </>
    );
};

export default ImmunizationRecord;
