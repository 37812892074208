import React from "react";
import { Formik } from "formik";
import { AMOUNT_ISC_CODES, FORM_FIELDS_FOR_INVOICE, INVOICE_PRICE_COMPONENT_CODE_OPTIONS, INVOICE_PRICE_COMPONENT_TYPE_OPTIONS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Icons from "../../../../../components/icons/Icons";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
const fields = [
    { fieldName: FORM_FIELDS_FOR_INVOICE.CURRENCY, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_CODE, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_TYPE, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_INVOICE.AMOUNT, isRequired: true },
]
const validationSchema = getValidationSchema(fields);

const LinePriceComponent = ({onConfirm, close}) => {

    return(
        <>
            <Formik
                initialValues={{
                    [FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_TYPE]:"",
                    [FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_CODE]:"",
                    [FORM_FIELDS_FOR_INVOICE.AMOUNT]:"",
                    [FORM_FIELDS_FOR_INVOICE.CURRENCY]:"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const data = {
                        type: values[FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_TYPE].value,
                        code: {
                            code: values[FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_CODE].value,
                            display: values[FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_CODE].label,
                        },
                        amount: {
                            value: Number(values[FORM_FIELDS_FOR_INVOICE.AMOUNT]),
                            currency: values[FORM_FIELDS_FOR_INVOICE.CURRENCY].value
                        }
                    }
                    onConfirm((prev) => [...prev, { type: `Price component ${prev.length + 1}`, data }]);
                    close();
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                    <div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl">
                        <div className="flex justify-between items-center border-b pb-3">
                            <Heading type={HEADING.H2} className="text-lg font-semibold">Add Price Component</Heading>
                            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                                <Icons iconName={"closeIcon"}/>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className="grid grid-cols-2 gap-4">
                                <SelectDropdown
                                    label="Price component type"
                                    name={FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_TYPE}
                                    placeholder="Select"
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_TYPE]}
                                    options={INVOICE_PRICE_COMPONENT_TYPE_OPTIONS}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue(FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_TYPE, selectedOption)
                                    }
                                />
                                <SelectDropdown
                                    label="Price component Code/Text"
                                    name={FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_CODE}
                                    placeholder="Select"
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_CODE]}
                                    options={INVOICE_PRICE_COMPONENT_CODE_OPTIONS}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue(FORM_FIELDS_FOR_INVOICE.PRICE_COMPONENT_CODE, selectedOption)
                                    }
                                />
                                <Input
                                    label="Value"
                                    placeholder="Please provide value"
                                    name={FORM_FIELDS_FOR_INVOICE.AMOUNT}
                                    type="number"
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.AMOUNT]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_INVOICE.AMOUNT, e.target.value)}
                                />
                                <SelectDropdown
                                    label="Currency"
                                    name={FORM_FIELDS_FOR_INVOICE.CURRENCY}
                                    isRequired={true}
                                    placeholder="Select"
                                    value={values[FORM_FIELDS_FOR_INVOICE.CURRENCY]}
                                    options={AMOUNT_ISC_CODES}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue(FORM_FIELDS_FOR_INVOICE.CURRENCY, selectedOption)
                                    }
                                />
                            </div>
                            <div className="flex justify-end">
                                <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    </>
    )
}
export default LinePriceComponent;