import React from "react";
import { Formik } from "formik";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";

const Condition = ({ title, value, open, close, onConfirm }) => {
    
    const validate = (values) => {
        const errors = {};
        if(!values.dischargeCondition){
            errors.dischargeCondition =  `Please provide the ${title}`
        }
        return errors;
    };

    return(
        <>
            <Formik
                initialValues={{
                    dischargeCondition:"",
                }}
                validate={validate}
                onSubmit={(values) => {
                    onConfirm((prev) => [...prev, {type: `${title} ${prev.length + 1}`, data: {resourceType: title.split(" ").join(''),code: { text: values.dischargeCondition}}}])
                    close()
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                 <ModalComponent
                    open={open}
                    title={`Add ${title}`}
                    footerButton={
                        <Button
                            variant={BUTTON_VARIANTS.CONTAINED}
                            onClickCb={handleSubmit}
                        >
                            Submit
                        </Button>
                    }
                    close={() => close()}
                    customClasses="w-[90%] max-w-[800px]"
                >
                    <Input
                        label={title}
                        placeholder={value}
                        isRequired={true}
                        name={"dischargeCondition"}
                        value={values.dischargeCondition}
                        onChangeCb={(e) => {
                            setFieldValue("dischargeCondition", e.target.value);
                        }}
                    />
                </ModalComponent>
            )}
        </Formik>
    </>
    )
}
export default Condition;