import React from "react";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";
import { FieldRow, formatDate } from "./FHIRBundleViewer";

const Immunization = ({ resource, bundle }) => {
  const getResourceByReference = (referenceId) => {
    const entry = bundle?.entry?.find((entry) => entry.fullUrl === referenceId);
    return entry?.resource;
  };

  const locationResource = getResourceByReference(resource.location?.reference);

  return (
    <div className="mb-4 border border-gray-200 p-6 rounded">
      <Heading type={HEADING.H2} className="text-xl font-semibold mb-4">
        {resource.resourceType}
      </Heading>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mt-4 p-4 border rounded">
        <FieldRow label="Status" value={resource.status} />
        <FieldRow label="Occurrence" value={formatDate(resource.occurrenceDateTime)} />
        <FieldRow label="Vaccine" value={resource.vaccineCode?.coding?.[0]?.display} />
        <FieldRow label="Lot Number" value={resource.lotNumber} />
        <FieldRow label="Expiration Date" value={formatDate(resource.expirationDate)} />
      </div>
      {locationResource && (
        <div className="mt-4 p-4 border rounded">
          <Heading type={HEADING.H4} className="font-medium mb-2">Location Details</Heading>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <FieldRow label="Name" value={locationResource.name} />
            <FieldRow label="Status" value={locationResource.status} />
            <FieldRow label="Contact" value={locationResource.telecom?.[0]?.value} />
            <FieldRow label="Address" value={locationResource.address?.[0]?.text} />
          </div>
        </div>
      )}
      {resource.reasonCode && resource.reasonCode.length > 0 && (
        <div className="mt-4 p-4 border-l-2 rounded">
          <Heading type={HEADING.H4} className="font-medium mb-2">Reasons</Heading>
          <div className="flex gap-4 flex-wrap">
            {resource.reasonCode.map((reason, index) => (
              <div key={index} className="p-2 bg-white rounded border border-gray-200">{reason.text}</div>
            ))}
          </div>
        </div>
      )}
      {resource.protocolApplied && resource.protocolApplied.length > 0 && (
        <div className="mt-4 p-4 border-l-2 ">
          <Heading type={HEADING.H4} className="font-medium mb-2">Protocol Applied</Heading>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {resource.protocolApplied.map((protocol, index) => (
              <FieldRow key={index} label={`Dose Number`} value={protocol.doseNumber} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Immunization;