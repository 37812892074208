import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey, setBundlePushError, setBundlePushResponse, setSelectedBundle } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { createPrescriptionBundle, pushBundle, retryDataPush } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
import HealthRecordHelper from "../utils/utils";
import MedicationRequest from "./MedicationRequest";
import LoadingPopup from "../LoadingPopup";
import Encounter from "./Encounter";

const PrescriptionRecord = ({ handleCancle, payloadHelper, title, setTitleError, setTitleSubmitted }) => {
  const { patientData, selectedBundle, bundlepushError } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [encounterItem, setEncounterItem] = useState([]);
  const [reportsList, setReportsList] = useState([]);

  const [isEncounterPopupOpen, setIsEncounterPopupOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [encounterError, setEncounterError] = useState("");
  const [reportsError, setReportsError] = useState("");
  const [loadingpopup, setLoadingPopup] = useState(false);
  const [isLinkingFailed, setIsLinkingFailed] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.compositionStatus || values.compositionStatus.value === "Select Status") {
      errors.compositionStatus = "Please select composition status.";
    }
    if (!values.prescriptionDate) {
      errors.prescriptionDate = "Please provide a date and time.";
    }
    if (!values.prescriptionTitle) {
      errors.prescriptionTitle = "Please provide a title.";
    }
    if (!title) {
      setTitleError("Please provide the title");
    }
    if (encounterItem.length === 0) {
      setEncounterError("Encounter entry is required.");
    }
    if (reportsList.length === 0) {
      setReportsError("At least 1 report is required.");
    }
    return errors;
  };

  useEffect(() => {
    if(reportsList.length > 0) setReportsError("");
    if (encounterItem.length > 0) setEncounterError("");
  }, [reportsList, encounterItem]);

  useEffect(() => {
    if(selectedBundle){
      const payload = {
        ...payloadHelper,
        patientId: patientData?.uuid,
        bundleData: selectedBundle,
      }
      if(isLinkingFailed) {
        payload.previous = true;
        dispatch(retryDataPush(payload))
      } else {
        dispatch(pushBundle(payload));
      }
      setLoadingPopup(true)
    }
  }, [dispatch, selectedBundle]);
  
  useEffect(() => {
    if (bundlepushError?.statusCode) {
      setLoadingPopup(false);
      setIsLinkingFailed(true)
      dispatch(setBundlePushError({}))
      dispatch(setSelectedBundle(null))
    }
  }, [bundlepushError, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedBundle(null))
      dispatch(setBundlePushResponse({}))
      dispatch(setBundlePushError({}))
    }
  }, [dispatch]);

  const structurePayloadWithAllData = (values) => {
    const payload = {
      status: values.compositionStatus.value,
      date: values.prescriptionDate,
      title: values.prescriptionTitle,
      subject: HealthRecordHelper.getStructurePatientData(patientData),
      encounter: encounterItem[0]?.data,
      entry: reportsList?.map((report) => report.medicationRequestReport),
    };
    return payload;
  };

  return (
    <>
      <Formik
        initialValues={{
          compositionStatus: { label: "Select Status", value: "Select Status" },
          prescriptionDate: "",
          prescriptionTitle: "",
        }}
        validate={validate}
        onSubmit={(values) => {
          dispatch(setSelectedBundle(null));
          if (reportsList.length === 0 || encounterItem.length === 0 || !title) {
            return;
          }
          
          const payload = structurePayloadWithAllData(values);
          dispatch(createPrescriptionBundle(payload))
        }}
      >
        {({ values, errors, setFieldValue, handleSubmit }) => (
          <form 
            onSubmit={(e) => {
              setTitleSubmitted(true)
              setSubmitAttempted(true)
              handleSubmit(e);
            }} 
            className="h-full w-full flex flex-col gap-1 justify-between"
          >
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
              <SelectDropdown
                label="Composition Status"
                name="compositionStatus"
                isRequired={true}
                customClasses="w-full"
                value={values.compositionStatus}
                options={[{ label: "Select Status", value: "Select Status" }, ...RECORD_STATUS]}
                onChangeCb={(selectedOption) =>
                  setFieldValue("compositionStatus", selectedOption)
                }
              />
              <Input
                label="Prescription Editing Time"
                name="prescriptionDate"
                type="datetime-local"
                isRequired={true}
                value={values.prescriptionDate}
                onChangeCb={(e) => setFieldValue("prescriptionDate", e.target.value)}
              />
              <Input
                label="Title"
                placeholder="Human Readable name/title"
                type="text"
                name="prescriptionTitle"
                isRequired={true}
                value={values.prescriptionTitle}
                onChangeCb={(e) => setFieldValue("prescriptionTitle", e.target.value)}
              />
              
              <div className="flex flex-col col-span-2 sm:col-span-3">
                <ItemManager
                  key={`op_encounter_entry_prescription`}
                  title="Encounter entry"
                  isRequired = {true}
                  items={encounterItem}
                  setItems={setEncounterItem}
                  setIsPopupOpen={(value) => {
                    if (encounterItem.length < 1) {
                      setIsEncounterPopupOpen(value)
                    } else setEncounterError("Only one encounter is allowed")
                  }}
                  customHeight="52"
                />
                {submitAttempted && encounterError && (
                  <Label fontSize="md" color="red-500">
                    {encounterError}
                  </Label>
                )}
              </div>
              
              <div className="flex flex-col col-span-2 sm:col-span-3 h-">
                <ItemManager
                  title="Report entry"
                  isRequired = {true}
                  items={reportsList}
                  setItems={setReportsList}
                  setIsPopupOpen={setIsPopupOpen}
                  customHeight="40"
                />
                {submitAttempted && reportsError && 
                  <Label fontSize="md" color="red-500">{reportsError}</Label>
                }
              </div>
            </div>
            
            <div className="flex justify-end gap-2">
              <Button 
                onClickCb={handleCancle} 
                variant={BUTTON_VARIANTS.OUTLINED}
              >Cancel</Button>
              <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                {isLinkingFailed ? "Try Again" : "Link to ABDM"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
      
      {isEncounterPopupOpen && 
        <Encounter
          open={isEncounterPopupOpen}
          onConfirm={setEncounterItem}
          close={() => setIsEncounterPopupOpen(false)}
        />
      }
      
      {isPopupOpen && 
        <MedicationRequest 
          onConfirm={setReportsList} 
          close={() => setIsPopupOpen(false)}
        />
      }
      
      <LoadingPopup
        isOpen={loadingpopup} 
        handleCancle = {() => {
          setLoadingPopup(false)
          handleCancle()
        }}
        handleClose = {() => setLoadingPopup(false)}
      />
    </>
  );
};

export default PrescriptionRecord;
