import React, { useEffect, useRef } from "react";
import { componentKey as PatientDashboardComponentKey, setConsetRequestData } from "../../PatientDashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { io } from 'socket.io-client';
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import SandClock from "../AddRecords/SandClock";
import "../AddRecords/SandClock.css";
import Icons from "../../../../../components/icons/Icons";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";
import { consentIdRequest } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
const socketURL = process.env.REACT_APP_API_URL.replace(/\/api$/, '');

function ConsentInitRequestPopup ({close, onConfirm, data}) {
    const { patientData, consentRequestData } = useSelector((state) => state[PatientDashboardComponentKey]);
    const socketRef = useRef(null);
    const dispatch = useDispatch();
    
    const handleGenerate = () => {
        const payload = {
            patientId: patientData.uuid,
            startDate: new Date(data.from).toISOString(),
            endDate: new Date(data.to).toISOString(),
            hiTypes: data.hiTypes,
        }
        dispatch(consentIdRequest(payload));
    }

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(socketURL);
        }
        const socket = socketRef.current;
        let timeout;
        socket.on("connect", () => {
            startEmitting();
            timeout = setTimeout(async () => {
                handleGenerate()
            }, 1000)
        });

        socket.on("getConsentRequest", (data) => {
            if (data) {
                console.log("socket data (getConsentRequest)", data);
                if (data) {
                    dispatch(setConsetRequestData(data));
                    stopEmitting(socket);
                }
            }
        });

        return () => {
            clearTimeout(timeout);
            socket.off("getConsentRequest")
            if (socket) {
                socket.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    const startEmitting = () => {
        if (socketRef.current) {
            socketRef.current.emit("getConsentRequest");
        }
    };

    const stopEmitting = (socket) => {
        socket.off("getConsentRequest")
        if (socketRef.current) {
            socketRef.current.emit("getConsentRequest", { action: "stop" });
        }
    };

    return(
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
            <div className="bg-white shadow-lg rounded-lg p-4 w-full max-w-2xl max-h-screen overflow-y-auto">
                {!consentRequestData && 
                    <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                        <Icons iconName={"closeIcon"}/>
                    </div>
                }
                {consentRequestData ?  
                    (<div className="flex flex-col gap-4 p-2 items-center w-full">
                        <div className="flex flex-col items-center justify-center w-full">
                            <SuccessSvg />
                            <Label fontSize="lg" fontWeight="bold">Consent init request is successful</Label>
                        </div>
                    
                        <div className="flex justify-end w-full">
                            <Button 
                                onClickCb={() => onConfirm()} 
                                variant={BUTTON_VARIANTS.CONTAINED} 
                                customBtnClass="px-4"
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                     ):(
                    <div className="flex flex-col gap-4 justify-center items-center p-2">
                        <SandClock/>
                        <h1 className="font-bold text-lg">Please wait for consent init request</h1>
                    </div>
                    )
                }
                
            </div>
        </div>
    )
}
export default ConsentInitRequestPopup;