import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { FORM_FIELDS_FOR_LOCATION, LOCATION_STATUS_OPTIONS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Icons from "../../../../../components/icons/Icons";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import ItemManager from "../ItemManager";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import AddTelecomOrAddress from "./AddTelecomOrAddress";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import Label from "../../../../../components/common-components/label/Label";
import HealthRecordHelper from "../utils/utils";
const fields = [
    { fieldName: FORM_FIELDS_FOR_LOCATION.STATUS, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_LOCATION.NAME, isRequired: true }
];

const validationSchema = getValidationSchema(fields);

const Location = ({onConfirm, close}) => {
    const [telecomList, setTelecomList] = useState([]);
    const [addressList, setAddressList] = useState([]);

    const [isTelecomPopupOpen, setIsTelecomPopupOpen] = useState(false);
    const [isAddressPopupOpen, setIsaddressPopupOpen] = useState(false);
    const [addressError, setAddressError] = useState("");

    useEffect(() => {
        if(addressList) setAddressError("");
    },[addressList])
    return(
        <Formik
            initialValues={{
                [FORM_FIELDS_FOR_LOCATION.STATUS]: "",
                [FORM_FIELDS_FOR_LOCATION.NAME]: "",
                [FORM_FIELDS_FOR_LOCATION.TELECOM_SYSTEM]: "",
                [FORM_FIELDS_FOR_LOCATION.TELECOM_USE]: "",
                [FORM_FIELDS_FOR_LOCATION.TELECOM_VALUE]: "",
                [FORM_FIELDS_FOR_LOCATION.ADDRESS_TEXT]: "",
            }}
        
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const data = {
                    status: values[FORM_FIELDS_FOR_LOCATION.STATUS]?.value,
                    name: values[FORM_FIELDS_FOR_LOCATION.NAME],
                    telecom: telecomList.map((t) => t.data),
                    address: addressList.map((a) => a.data)[0],
                };
                const cleanedData = HealthRecordHelper.removeEmptyFields(data)
                onConfirm((prev) => [...prev, { type: cleanedData.name, data: cleanedData }]);
                close();
            }}
        >
            {({ values, errors, setFieldValue, handleSubmit }) => (
            <>
                <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                    <div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl min-h-[35%] max-h-[90%] overflow-y-auto">
                        <div className="flex justify-between items-center border-b pb-3">
                            <Heading type={HEADING.H2} className="text-lg font-semibold">Add Location</Heading>
                            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                                <Icons iconName={"closeIcon"}/>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className=" grid grid-cols-2 gap-2">
                                <SelectDropdown
                                    label="Composition Status"
                                    name={FORM_FIELDS_FOR_LOCATION.STATUS}
                                    isRequired={true}
                                    placeholder="Select"
                                    value={values[FORM_FIELDS_FOR_LOCATION.STATUS]}
                                    options={LOCATION_STATUS_OPTIONS}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue(FORM_FIELDS_FOR_LOCATION.STATUS, selectedOption)
                                    }
                                />
                                <Input
                                    label="Name"
                                    isRequired={true}
                                    placeholder="Name used for the location"
                                    name={FORM_FIELDS_FOR_LOCATION.NAME}
                                    value={values[FORM_FIELDS_FOR_LOCATION.NAME]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_LOCATION.NAME, e.target.value)}
                                />
                                <ItemManager
                                    key={`OrganizartionTelecom`}
                                    title={ "Telecom"}
                                    items={telecomList}
                                    setItems={setTelecomList}
                                    setIsPopupOpen={setIsTelecomPopupOpen}
                                    customHeight="52"
                                    customClass={"col-span-2"}
                                />
                                <div className="col-span-2">
                                    <ItemManager
                                        key={`OrganizartionAddress`}
                                        title={ "Address"}
                                        items={addressList}
                                        setItems={setAddressList}
                                        setIsPopupOpen={(value) => {
                                            if(addressList.length < 1) setIsaddressPopupOpen(value)
                                            else setAddressError("Only one address is allowed")
                                            
                                        }}
                                        customHeight="52"
                                    />
                                    {addressError && (
                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                            {addressError}
                                        </Label>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                {isTelecomPopupOpen && 
                    <AddTelecomOrAddress 
                        isTelecomPopupOpen={isTelecomPopupOpen}
                        setTelecomList={setTelecomList}
                        close={() => setIsTelecomPopupOpen(false)}
                    />
                }
                {isAddressPopupOpen && 
                    <AddTelecomOrAddress 
                        isAddressPopupOpen={isAddressPopupOpen}
                        setAddressList={setAddressList}
                        close={() => setIsaddressPopupOpen(false)}
                    />
                }
            </>
            )}
        </Formik>
    )
}
export default Location;