import React from "react";
import { Formik } from "formik";
import { APPOINTMENT_PARTICIPANT_STATUS_OPTIONS, FORM_FIELDS_FOR_APPOINTMENT } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import HealthRecordHelper from "../utils/utils";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { getValidationSchema } from "../../../../../libs/formsUtils";
const fields = [
    { fieldName: FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_STATUS, isRequired: true, isDropdown: true },
];
const validationSchema = getValidationSchema(fields);

const AppointmentParticipant = ({ open, close, onConfirm }) => {


    return(
        <>
            <Formik
                initialValues={{
                    [FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_TYPE]:"",
                    [FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_STATUS]:"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {

                    const data= {
                       status: values[FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_STATUS].value,
                       ...(values[FORM_FIELDS_FOR_APPOINTMENT.APPOINTMENT_TYPE] && {type: {
                            text: values[FORM_FIELDS_FOR_APPOINTMENT.APPOINTMENT_TYPE]
                       }})
                    }
                    const formattedData = HealthRecordHelper.removeEmptyFields(data)
                    onConfirm((prev) => [...prev, {type: `Participant ${prev.length + 1}`, data: formattedData}])
                    close()
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                 <ModalComponent
                    open={open}
                    title={"Participant"}
                    footerButton={
                        <Button
                            variant={BUTTON_VARIANTS.CONTAINED}
                            onClickCb={() => handleSubmit()}
                        >
                            Submit
                        </Button>
                    }
                    close={() => close()}
                    customClasses="w-[90%] max-w-[800px]"
                    customBodyClasses="overflow-visible"
                >
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <SelectDropdown
                            label="Participant Status"
                            name={FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_STATUS}
                            isRequired={true}
                            placeholder="Select"
                            value={values[FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_STATUS]}
                            options={APPOINTMENT_PARTICIPANT_STATUS_OPTIONS}
                            onChangeCb={(selectedOption) =>
                                setFieldValue(FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_STATUS, selectedOption)
                            }
                        />
                        <Input
                            label="Participant type"
                            placeholder="Role of participant in the appointment"
                            name={FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_TYPE}
                            value={values[FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_TYPE]}
                            onChangeCb={(e) =>
                                setFieldValue(FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_TYPE, e.target.value)
                            }
                        />
                    </div>
                </ModalComponent>
            )}
        </Formik>
    </>
    )
}
export default AppointmentParticipant;