import React, { useRef, useState, useEffect, useCallback } from "react";
import { ABHA_HEALTH_RECORD_NAVS, ABHA_HEALTH_RECORD_VIEW_NAVS } from "../Constants";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Label from "../../../../../components/common-components/label/Label";
import ConsentStatusPopup from "./ConsentStatusPopup";
import { componentKey as PatientDashboardComponentKey, setDecryptedData, setHUInotifyData } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import SuccesStepIcon from "../../../../../components/icons/vectors/SuccesStepIcon";
import LoadingIcon from "../../../../../components/icons/vectors/LoadingIcon";
import { io } from "socket.io-client";
import { getDecryptedData } from "../../PatientDashboardSaga";
import WarningIcon from "../../../../../components/icons/vectors/WarningIcon";
import { useNavigate } from "react-router-dom";

const socketURL = process.env.REACT_APP_API_URL.replace(/\/api$/, "");

const ConsentStatus = ({ onConfirm, handleCancle }) => {
    const { patientData, huiNotifyData, decryptedData, bundlesData } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [decryptedDataError, setDecryptedDataError] = useState(false);
    const isHUInotifyDataReceivedRef = useRef(false);
    const isDecryptedDataReceivedRef = useRef(false);
    const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);
    const socketRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const startHUInotifyEmitting = useCallback(() => {
        socketRef.current?.emit("getHIUnotify");
    }, []);

    const startDecryptEmitting = useCallback(() => {
        socketRef.current?.emit("getDecryptedData");
    }, []);

    const stopHUInotifyEmitting = useCallback((socket) => {
        socket.off("getHIUnotify")
        socketRef.current?.emit("getHIUnotify", { action: "stop" });
    }, []);

    const stopDecryptEmitting = useCallback((socket) => {
        socket.off("getDecryptedData")
        socketRef.current?.emit("getDecryptedData", { action: "stop" });
    }, []);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(socketURL);
        }
        const socket = socketRef.current;

        socket.on("connect", () => {
            startHUInotifyEmitting();
            startDecryptEmitting();
        });

        socket.on("getHIUnotify", (data) => {
            if (data && !isHUInotifyDataReceivedRef.current) {
                console.log("socket data (getHIUnotify)", data);
                isHUInotifyDataReceivedRef.current = true;
                dispatch(setHUInotifyData(data));
                stopHUInotifyEmitting(socket);
            }
        });

        socket.on("getDecryptedData", (data) => {
            if (data && !isDecryptedDataReceivedRef.current) {
                console.log("socket data (getDecryptedData)", data);
                isDecryptedDataReceivedRef.current = true;
                dispatch(setDecryptedData(data));
                stopDecryptEmitting(socket);
            }
        });

        return () => {
            socket.off("getHIUnotify");
            socket.off("getDecryptedData");
            socket.disconnect();
            socketRef.current = null;
        };
    }, [startHUInotifyEmitting, startDecryptEmitting, stopHUInotifyEmitting, stopDecryptEmitting, dispatch]);

    useEffect(() => {
        if(decryptedData?.entries.length === 0 && huiNotifyData) {
            setDecryptedDataError(true)
        }
        if (decryptedData?.entries.length > 0 && huiNotifyData) {
            const payload = {
                entries: decryptedData.entries,
                payloadData: {
                    senderNonce: decryptedData?.keyMaterial?.nonce,
                    senderPublicKey: decryptedData?.keyMaterial?.dhPublicKey?.keyValue,
                    patientId: patientData?.uuid,
                }
            };
            dispatch(getDecryptedData(payload));
        }
    }, [decryptedData?.entries, patientData?.uuid, dispatch, decryptedData?.keyMaterial?.nonce, decryptedData?.keyMaterial?.dhPublicKey?.keyValue, huiNotifyData]);

    const closePopup = () => setIsStatusPopupOpen(false);

    const proceedToNext = () => {
        closePopup();
        onConfirm(ABHA_HEALTH_RECORD_VIEW_NAVS.VIEW_RECORDS);
    };
    const allStepsCompleted = huiNotifyData && decryptedData && bundlesData;

    return (
        <div className="flex flex-col h-full justify-between p-4">
            <div className="flex flex-col md:flex-row gap-6 p-6 bg-white shadow-xl rounded-2xl w-full max-w-[80%] mx-auto transition-all">
                <div className="flex flex-col gap-6 w-full md:w-3/4 p-4">
                    <h1 className="text-xl font-semibold text-gray-800 text-center md:text-left">
                        {`${allStepsCompleted ? "Your request is processed, click on next to view records" : "Processing your request, please wait..."}`}
                    </h1>
                    <div className="flex flex-col gap-4 bg-gray-100 p-4 rounded-lg shadow-md">
                        <div className="flex gap-4 items-center">
                            {huiNotifyData?.notification?.status === "GRANTED" ? (
                                <>
                                    <SuccesStepIcon className="animate-bounce" />
                                    <Label fontSize={"lg"}>Patient approval received via ABHA app.</Label>
                                </>
                            ) : (
                                <>
                                    <LoadingIcon color="text-yellow-400" />
                                    <Label fontSize={"lg"} color="gray-600">Waiting for consent request patient approval in ABHA app...</Label>
                                </>
                            )}
                        </div>
                        <div className="flex gap-4 items-center">
                            {huiNotifyData?.notification?.status === "GRANTED" && decryptedData ? (
                                <>
                                    <SuccesStepIcon className="animate-bounce" />
                                    <Label fontSize={"lg"}>Successfully received encrypted records data.</Label>
                                </>
                            ) : (
                                <>
                                    <LoadingIcon color="text-yellow-400" />
                                    <Label fontSize={"lg"} color="gray-600">Waiting for encrypted records data...</Label>
                                </>
                            )}
                        </div>
                        {huiNotifyData?.notification?.status === "GRANTED" && decryptedData && (
                            <div className="flex gap-4 items-center">
                                {decryptedData && bundlesData ? (
                                    <>
                                        <SuccesStepIcon className="animate-bounce" />
                                        <Label fontSize={"lg"}>Health records successfully retrieved and decrypted.</Label>
                                    </>
                                ) : (
                                    <>
                                        {!decryptedDataError ? <LoadingIcon color="text-yellow-400" /> : <WarningIcon/>}
                                        <Label fontSize={"lg"} color="gray-600">{decryptedDataError ? "No records found" :"Retrieving and decrypting health records..."}</Label>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center w-full md:w-1/4 gap-4">
                    <Button
                        onClickCb={() => setIsStatusPopupOpen(true)}
                        variant={BUTTON_VARIANTS.CONTAINED}
                        customBtnClass="px-6 py-3 bg-blue-600 hover:bg-blue-600 text-white font-bold transition-all"
                        disabled={allStepsCompleted || decryptedDataError}
                    >
                        Check Status
                    </Button>
                    <Label fontSize="sm" fontWeight="bold" color="red-500" className="text-center">
                        Click to check the real-time status of your request.
                    </Label>
                </div>
            </div>
            <div className="flex gap-4 justify-end">
                <Button 
                    onClickCb={handleCancle} 
                    variant={BUTTON_VARIANTS.OUTLINED}
                >Cancel</Button>
                <Button 
                    onClickCb={proceedToNext} 
                    variant={BUTTON_VARIANTS.CONTAINED}
                    disabled={!allStepsCompleted}
                >Next</Button>
            </div>
            {isStatusPopupOpen && (
                <ConsentStatusPopup
                    onConfirm={closePopup}
                    close={closePopup}
                />
            )}
        </div>
    );
};

export default ConsentStatus;
