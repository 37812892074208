import React from "react";
import { Formik } from "formik";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { CLINICAL_STATUS_OPTIONS, DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE } from "../Constants";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import HealthRecordHelper from "../utils/utils";
import { getValidationSchema } from "../../../../../libs/formsUtils";
const fields = [
    { fieldName: DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CLINICAL_STATUS, isRequired: true, isDropdown: true },
    { fieldName: DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CODE, isRequired: true}
]
const validationSchema = getValidationSchema(fields);

const AllergyIntolerance = ({ open, close, onConfirm }) => {

    return(
        <>
            <Formik
                initialValues={{
                    [DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CLINICAL_STATUS]:"",
                    [DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CODE]:"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const data= {
                        clinicalStatus: values[DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CLINICAL_STATUS].value,
                        code: {
                            text: values[DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CODE],
                        }
                    }
                    const formattedData = HealthRecordHelper.removeEmptyFields(data)
                    onConfirm((prev) => [...prev, {type: `Allery/Intolerance ${prev.length + 1}`, data: formattedData}])
                    close()
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                 <ModalComponent
                    open={open}
                    title={`Add Allery/Intolerance`}
                    footerButton={
                        <Button
                            variant={BUTTON_VARIANTS.CONTAINED}
                            onClickCb={handleSubmit}
                        >
                            Submit
                        </Button>
                    }
                    close={() => close()}
                    customClasses="w-[90%] max-w-[800px]"
                    customBodyClasses="overflow-visible"
                >
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <SelectDropdown
                            label="Clinical Status"
                            name={DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CLINICAL_STATUS}
                            isRequired={true}
                            placeholder="select"
                            value={values[DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CLINICAL_STATUS]}
                            options={CLINICAL_STATUS_OPTIONS}
                            onChangeCb={(selectedOption) =>
                                setFieldValue(DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CLINICAL_STATUS, selectedOption)
                            }
                        />
                        <Input
                            label="code/text"
                            placeholder="Code that identifies the allergy or intolerance"
                            name={DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CODE}
                            value={values[DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CODE]}
                            isRequired={true}
                            onChangeCb={(e) => {
                                setFieldValue(
                                    DISCHARGE_SUMMARY_ALLERGY_INTOLERANCE.CODE,
                                    e.target.value
                                );	
                            }}
                        />
                    </div>
                </ModalComponent>
            )}
        </Formik>
    </>
    )
}
export default AllergyIntolerance;