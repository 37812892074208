import "./SandClock.css"
const SandClock = () => {
  return (
    <div className="flex justify-center items-center h-48 w-48">
      <div className="relative flex flex-col justify-between items-center w-24 h-40 border-4 border-black rounded-lg overflow-hidden">
        <div className="sand-top w-16 h-4 bg-sand rounded-md absolute top-4 sandMovement"></div>
        <div className="sand-bottom w-16 h-4 bg-sand rounded-md absolute bottom-4 sandMovement"></div>
      </div>
    </div>
  );
};

export default SandClock;