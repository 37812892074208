import React from "react";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";
import { FieldRow } from "./FHIRBundleViewer";

const Invoice = ({ bundle }) => {
    const getResourceByReference = (referenceId) => {
        return bundle?.entry?.find((entry) => entry.fullUrl === referenceId)?.resource;
    };

    const invoiceEntry = bundle?.entry?.find(
        (entry) => entry.resource.resourceType === "Invoice"
    );

    if (!invoiceEntry) {
        return <div>No Invoice Found</div>;
    }

    const invoice = invoiceEntry.resource;

    return (
        <div className="mb-4 border border-gray-200 p-6 rounded">
            <Heading type={HEADING.H2} className="text-xl font-semibold mb-4">
                Invoice Details
            </Heading>
            {invoice.lineItem && invoice.lineItem.length > 0 ? (
                invoice.lineItem.map((lineItem, index) => {
                const chargeItemResource = getResourceByReference(
                    lineItem.chargeItemReference?.reference
                );

                return (
                    <div key={index} className="mb-4 p-4 border rounded">
                    <Heading type={HEADING.H3} className="font-medium mb-2">
                        {`Line Item ${index + 1}`}
                    </Heading>

                    {lineItem.priceComponent && lineItem.priceComponent.length > 0 && (
                        <div className="mt-4 p-4 border rounded-lg">
                        <Heading type={HEADING.H3} className="font-medium mb-2">
                            Price Components
                        </Heading>
                        {lineItem.priceComponent.map((price, idx) => (
                            <div
                            key={idx}
                            className="grid grid-cols-2 sm:grid-cols-3 gap-2 mt-2"
                            >
                            <FieldRow
                                label="Price component"
                                value={
                                    price.code?.text || price.code?.coding?.[0]?.display
                                }
                            />
                            <FieldRow label="Amount" value={`${price.amount?.value} ${price.amount?.currency}`} />
                            </div>
                        ))}
                        </div>
                    )}
                    {chargeItemResource && (
                        <div className="mt-4 p-4 border-l-2">
                            <Heading type={HEADING.H3} className="font-medium mb-2">
                                Charge Item Details
                            </Heading>
                            <FieldRow
                                label="Charge code/text"
                                value={chargeItemResource.code?.text}
                            />
                            <FieldRow
                                label="Quantity"
                                value={`${chargeItemResource.quantity.value} ${chargeItemResource.quantity.unit}`}
                            />
                            <FieldRow
                                label="Occurrence"
                                value={`${chargeItemResource.occurrence} ${chargeItemResource.quantity.unit}`}
                            />

                        </div>
                    )}
                </div>
            );
            })
        ) : (
            <div>No line items available.</div>
        )}
        </div>
    );
};

export default Invoice;
