import React, { useState } from "react";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Input from "../../../../../components/common-components/input/Input";
import { ABHA_HEALTH_RECORD_VIEW_NAVS, FORM_FIELDS_FOR_CONSENT_INIT } from "../Constants";
import ConsentInitRequestPopup from "./consentInitRequestPopup";
import { FaFileMedical, FaNotesMedical, FaPills, FaHeartbeat, FaUserMd, FaSyringe, FaReceipt } from "react-icons/fa";
import * as Yup from 'yup';

const endOfToday = new Date();
endOfToday.setHours(23, 59, 59, 999);

const validationSchema = Yup.object().shape({
    [FORM_FIELDS_FOR_CONSENT_INIT.FROM]: Yup.date()
      .max(endOfToday, "'From' date cannot be in the future")
      .required("From date is required"),
    [FORM_FIELDS_FOR_CONSENT_INIT.TO]: Yup.date()
      .min(Yup.ref(FORM_FIELDS_FOR_CONSENT_INIT.FROM), "'To' date cannot be earlier than 'From' date")
      .max(endOfToday, "'To' date cannot be in the future")
      .required("To date is required"),
    selectedHiTypes: Yup.array()
      .min(1, "At least one health information type must be selected"),
});

const HI_TYPES = {
    DIAGNOSTIC: { label: "Diagnostic Report", icon: <FaFileMedical />, value: "DiagnosticReport" },
    DISCHARGE: { label: "Discharge Summary", icon: <FaNotesMedical />, value: "DischargeSummary" },
    PRESCRIPTION: { label: "Prescription", icon: <FaPills />, value: "Prescription" },
    WELLNESS: { label: "Wellness Record", icon: <FaHeartbeat />, value: "WellnessRecord" },
    OP_CONSULTATION: { label: "OP Consultation", icon: <FaUserMd />, value: "OPConsultation" },
    IMMUNIZATION: { label: "Immunization Record", icon: <FaSyringe />, value: "ImmunizationRecord" },
    INVOICE: { label: "Invoice", icon: <FaReceipt />, value: "Invoice" },
    HEALTH_DOCUMENT: { label: "Health Document Record", icon: <FaReceipt />, value: "HealthDocumentRecord" }
};

const ConsentInitRequest = ({ onConfirm, handleCancle }) => {
    const [isLoadingOpen, setIsLoadingOpen] = useState(false);

    return (
        <Formik
            initialValues={{
                [FORM_FIELDS_FOR_CONSENT_INIT.FROM]: '',
                [FORM_FIELDS_FOR_CONSENT_INIT.TO]: '',
                selectedHiTypes: [],
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setIsLoadingOpen(true);
            }}
        >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => (
                <div className="h-full flex flex-col gap-4 p-4">
                    <div className="text-center">
                        <h2 className="text-2xl font-bold text-gray-800">Consent Initialization Request</h2>
                        <p className="text-gray-600">Select date range and health information types.</p>
                    </div>

                    <form onSubmit={handleSubmit} className="h-full flex flex-col justify-between gap-6">
                        <div className="flex gap-4">
                            <Input
                                label="From"
                                isRequired
                                type="datetime-local"
                                name={FORM_FIELDS_FOR_CONSENT_INIT.FROM}
                                value={values[FORM_FIELDS_FOR_CONSENT_INIT.FROM]}
                                onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_CONSENT_INIT.FROM, e.target.value)}
                                error={touched[FORM_FIELDS_FOR_CONSENT_INIT.FROM] && errors[FORM_FIELDS_FOR_CONSENT_INIT.FROM]}
                            />
                            <Input
                                label="To"
                                isRequired
                                type="datetime-local"
                                name={FORM_FIELDS_FOR_CONSENT_INIT.TO}
                                value={values[FORM_FIELDS_FOR_CONSENT_INIT.TO]}
                                onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_CONSENT_INIT.TO, e.target.value)}
                                error={touched[FORM_FIELDS_FOR_CONSENT_INIT.TO] && errors[FORM_FIELDS_FOR_CONSENT_INIT.TO]}
                            />
                        </div>

                        <div className="p-4 rounded-lg shadow bg-light">
                            <h3 className="text-lg font-semibold mb-2">
                                Select Health Information Types <span className="text-red-600">*</span>
                            </h3>
                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                                {Object.entries(HI_TYPES).map(([key, { label, icon, value }]) => (
                                    <div
                                        key={value}
                                        className={`p-3 border rounded-lg flex flex-col items-center cursor-pointer transition-all ${
                                            values.selectedHiTypes.includes(value)
                                                ? "bg-met-secondary shadow-lg scale-95"
                                                : "bg-white text-gray-800 hover:bg-gray-100"
                                        }`}
                                        onClick={() => {
                                            const updatedSelection = values.selectedHiTypes.includes(value)
                                                ? values.selectedHiTypes.filter(t => t !== value)
                                                : [...values.selectedHiTypes, value];
                                            setFieldValue("selectedHiTypes", updatedSelection);
                                        }}
                                    >
                                        <div className="text-2xl">{icon}</div>
                                        <span className="mt-2 text-sm font-medium">{label}</span>
                                    </div>
                                ))}
                            </div>

                            {touched.selectedHiTypes && errors.selectedHiTypes && (
                                <div className="text-red-600 mt-2">{errors.selectedHiTypes}</div>
                            )}

                            <Button
                                onClickCb={() => {
                                    const allSelected = values.selectedHiTypes.length === Object.keys(HI_TYPES).length;
                                    setFieldValue("selectedHiTypes", allSelected ? [] : Object.values(HI_TYPES).map(item => item.value));
                                }}
                                variant={BUTTON_VARIANTS.OUTLINED}
                                customBtnClass="mt-3 w-100"
                            >
                                {values.selectedHiTypes.length === Object.keys(HI_TYPES).length ? "Deselect All" : "Select All"}
                            </Button>
                        </div>

                        <div className="flex justify-end gap-4">
                            <Button onClickCb={handleCancle} variant={BUTTON_VARIANTS.OUTLINED}>Cancel</Button>
                            <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                Submit
                            </Button>
                        </div>
                    </form>

                    {isLoadingOpen && (
                        <ConsentInitRequestPopup
                            onConfirm={() => onConfirm(ABHA_HEALTH_RECORD_VIEW_NAVS.CONSENT_STATUS)}
                            close={() => setIsLoadingOpen(false)}
                            data={{
                                from: values[FORM_FIELDS_FOR_CONSENT_INIT.FROM],
                                to: values[FORM_FIELDS_FOR_CONSENT_INIT.TO],
                                hiTypes: values.selectedHiTypes,
                            }}
                        />
                    )}
                </div>
            )}
        </Formik>
    );
};

export default ConsentInitRequest;
